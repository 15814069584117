<template>
  <!-- <div :class="`bg-${colorValue} pa-1 rounded`">
    {{ value }}
  </div> -->
  <v-chip
    :color="colorValue"
    class="pa-4 rounded"
  >
    {{ value }}
  </v-chip>
</template>

<script lang="ts" setup>
import { INPUTS_MAP } from '@/easy-form/lib/inputs.interface';
import type { CellViewComponentProps } from "@/utils/entity-grid/table-grid-to-entity";
import { computed } from 'vue';
const props = defineProps<CellViewComponentProps>();


const colorValue = computed(() => {
  // return props.value.replace(/^status-color-/, '').replace('gray', 'grey');
  const color = props.item?.['posDocument.documentStatus.color'].raw;
  if (!color) return 'grey';
  return INPUTS_MAP.MdiColor.convertIn(color);
});

</script>
