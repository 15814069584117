<template>
  <PosTableGrid
    :columns="columnsFilteredVisible"
    :properties="[]"
    :items="gridItems"
    :totalItems="total"
    :loading="localLoading || loading"
    :useExpandbleRows="props.useExpandbleRows"
    @pagination-update="onPaginationUpdate($event)"
  >
    <!-- :defaultPagination="presets.current.value.pagination" -->
    <template #expansion="{ item }">
      <slot
        name="expansion"
        :item="item"
      >
        <v-expansion-panel>
          <v-expansion-panel-title
            :style="item.rowColor?.background ? `background-color: ${item.rowColor.background}` : undefined"
          >
            {{ item.srcItem.raw }}
          </v-expansion-panel-title>
          <v-expansion-panel-text class="pt-4">
            {{ item.srcItem }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </slot>
    </template>

    <template #expanded="eProps">
      <slot
        name="expanded"
        v-bind="eProps"
      />
    </template>

    <template
      v-for="col in columns"
      #[safeSlotName(col.name)]="slotParams"
    >
      <slot
        :name="safeSlotName(col.name)"
        v-bind="{ ... slotParams }"
      />
    </template>

    <template #top>
      <slot name="top-left" />
      <slot name="top-filter" />
      <slot name="top-center">
        <v-spacer />
      </slot>

      <slot name="top-settings" />
      <slot name="top-right" />
    </template>
  </PosTableGrid>
</template>

<script lang="ts" setup>
import { computed, markRaw, ref, watch } from 'vue';

import { isUndefined, safeSlotName } from '@/utils/entity-grid/entity-grid-utils';
import type { EntityTypeMappings, FilterItem, OrderByItem, PaginatedQueryOverrides, PaginatedQueryParams } from '@ui-api3-sdk/api/gql';
import { entityFullGqlName, getTypeFields, GQL_QUERY_TYPE_WRAPPERS, usePaginatedEntity } from '@ui-api3-sdk/api/gql';

import { useToast } from '@/composables';
import { FilterBoxItem } from '@/utils/entity-grid/FilterBox.interface';
import { TableGridColumn, TableGridItem, TableGridPaginationState } from '@/utils/entity-grid/table-grid-interface';
import { apiErrorToString } from '@ui-api3-sdk/api/api3';

import debounce from 'lodash/debounce';

import { createColumnDefinitionsFromSchemaFields, unwrapEntityToTableGridItem } from '@/utils/entity-grid/table-grid-to-entity';
import PosTableGrid from './PosTableGrid.vue';

const props = defineProps<{
  id?: string;
  entityName: keyof EntityTypeMappings;
  columnsOverride?: Partial<TableGridColumn>[];
  useColumns?: string[];
  manualRefresh?: number; // increment this prop to force refresh
  forceFilters?: FilterItem[];
  loading?: boolean;
  queryOverrides?: PaginatedQueryOverrides;
  useExpandbleRows?: boolean;
}>();

// const useColumns = props.columns.map((column) => column.name);

const paginatedEntity = usePaginatedEntity(props.entityName, props.useColumns);
const { items, total, query, shortName } = paginatedEntity;

if (props.queryOverrides) {
  paginatedEntity.setQueryOverrides(props.queryOverrides);
}

const idColumn = `${shortName}.id`;
const filters = ref<FilterBoxItem[]>([]);

const fieldTypes = getTypeFields(entityFullGqlName(props.entityName));
if (!fieldTypes || fieldTypes.length === 0) throw new Error(`Entity ${props.entityName} not found`);

const getColumns = (fields?: string[]) => markRaw(createColumnDefinitionsFromSchemaFields(fieldTypes, fields));

const gridItems = computed<TableGridItem[]>(() =>
  items.value.map((item) => unwrapEntityToTableGridItem(shortName, item, fieldTypes)),
);


const columns = computed(() => {
  const cols = getColumns(props.useColumns);

  if (props.columnsOverride) {

    for (const col of props.columnsOverride) {
      const index = cols.findIndex((c) => c.name === col.name);
      if (index !== -1) {
        cols[index] = { ...cols[index], ...col };
      } else {
        cols.push(col as TableGridColumn);
      }
    }
  }
  return cols;
});

const columnsFilteredVisible = computed(() => columns.value.filter((column) => !column.hidden));

const localLoading = ref(false);

const requestParams = ref<{
  pagination: TableGridPaginationState;
  filters: FilterBoxItem[];
}>({
  pagination: {
    page: 1,
    limit: 10,
    orderBy: defaultOrderBy(),
  },
  filters: [],
});


function defaultOrderBy() {
  return props.useColumns?.includes(idColumn)
    ? [
      { key: idColumn, order: 'desc' as const },
    ]
    : undefined;
}

async function runQuery() {
  localLoading.value = true;
  // console.log('** runQuery:', JSON.stringify(requestParams.value));

  const userFilters = requestParams.value.filters.
    filter((filter) => filter.operation && !isUndefined(filter.value)).
    reduce((acc, filter) => {
      acc.push({
        field: filter.field,
        operation: filter.operation,
        value: filter.value,
      } as FilterItem);
      return acc;
    }, [] as FilterItem[]);

  const filters = [...userFilters, ...(props.forceFilters || [])];

  const orderBy: OrderByItem[] = requestParams.value.pagination?.orderBy?.map((ob) => ({
    field: ob.key,
    direction: ob.order === 'asc' ? 'ASC' : 'DESC',
  })) || [];

  const queryParams: PaginatedQueryParams = {
    page: Math.max(requestParams.value.pagination.page - 1, 0),
    limit: Math.min(requestParams.value.pagination.limit, 1000),
    orderBy,
    filters,
  };

  try {
    await query(queryParams);
  } catch (e: any) {
    useToast().error(apiErrorToString(e));
  }

  localLoading.value = false;

}

watch(
  [requestParams, () => props.forceFilters],
  debounce(runQuery, 750, { leading: false, trailing: true } ),
  { deep: true, immediate: true },
);


watch(() => filters.value, (filters) => {

  const wrapped = filters.map((item) => {

    const wrapEntry = GQL_QUERY_TYPE_WRAPPERS[item.fieldType as keyof typeof GQL_QUERY_TYPE_WRAPPERS];
    const defaultWrapFn = wrapEntry?.default;
    const exactWrapFn = wrapEntry?.[item.operation as keyof typeof wrapEntry];

    const wrapFn = exactWrapFn || defaultWrapFn || ((v: any) => v);
    return { ...item, value: wrapFn(item.value) };
  });

  requestParams.value.filters = wrapped;
  requestParams.value.pagination.page = 1;

}, { deep: true, immediate: true });


if (props.manualRefresh) {
  watch(() => props.manualRefresh, () => {
    requestParams.value = { ...requestParams.value };
  });
}

async function onPaginationUpdate(options: TableGridPaginationState) {

  // console.log('*** onPaginationUpdate:', options);

  const oldLimit = requestParams.value.pagination.limit;
  const limit = options.limit;

  const oldPage = requestParams.value.pagination.page;
  const page = options.page;
  if (oldLimit !== limit || oldPage !== page) {

    if (Number.isInteger(limit) && limit !== undefined)
      requestParams.value.pagination.limit = limit;

    if (Number.isInteger(page) && page !== undefined)
      requestParams.value.pagination.page = page;

    requestParams.value = { ...requestParams.value };
  }

  if (!options.orderBy || options.orderBy.length === 0) {
    requestParams.value.pagination.orderBy = defaultOrderBy();
  } else {
    requestParams.value.pagination.orderBy = options.orderBy;
  }

}


</script>
~/store/usePresetsStore
