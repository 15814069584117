import { defineAsyncComponent } from 'vue';

// structure components
const LayoutComponents = [
  'DashboardMenuItem',
  'DashboardMenuItemGroup',
  'DashboardMenuItemSubGroup',

  'DashboardAppBar',
  'DashboardDrawer',
  'DashboardDrawerTop',
  'DashboardSettings',
  'DashboardFooter',
  'DashboardView',
  'DashboardApp',
];

// content components
const ComponentsUI = [
  'LanguageSelect',

  'UserLogin',
  'SignupRegistration',
  'RegisterMember',
  'UserProfile',

  'Metrics',
  'Autoship',
  'MetricsHistory',
  'MetricWidget',
  'RefLink',

  'WalletBalance',
  'WalletHistory',
  'ScheduleHistory',
  'AccountToAccount',
  'PayoutRequest',

  'FrontlineTable',
  'DownlineTable',
  'GenealogyTable',
  'VisualTree',
  'VisualBinaryTree',
  'UserPop',
  'Auxx',
  'PayoutRequestHistory',
  'TransactionLog',
  'RefLinkParts',
  'LeaderBoard',
  'RankProgress',
  'ProgressMetric',
  'Feedback',
  'WalletToWallet',
  'LinkBox',
  'CarouselWidget',
  'BadgeBox',
  'MetricChartLine',
  'PropertiesBox',
  'AvatarBox',
  'WidgetCard',
  'LanguageSelectBtn',
  'JsonFeed',
  'CountdownTimer',
  'PosOrdersHistory',

  'ApiSelect',
  'GravManagerWrapper',
];

function loadComponentFactoryFn(componentName) {
  return async () => {
      let component = undefined;
      try {
        // console.log('Trying to load fascade', componentName);
        component = await import(`@/components/ui/${componentName}Facade.vue`);
      } catch (e) {
        // console.log('Trying to load w/o fascade', componentName);
        component = await import(`@/components/ui/${componentName}.vue`);
      }
      return component;
  };

}

export default {

  install(app) {

    LayoutComponents.forEach(componentName =>
      app.component(componentName, defineAsyncComponent(() => import(`@/components/layout/${componentName}.vue`)),
      ),
    );

    ComponentsUI.forEach(componentName =>
      app.component(`Ui${componentName === 'Auxx' ? 'Aux' : componentName}`, defineAsyncComponent(loadComponentFactoryFn(componentName)),
      ),
    );
  },

};
