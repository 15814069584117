<template>
  <div :class="['content', wrapperClasses]">
    <div class="country-code">
      <v-select
        v-model="countryCode"
        :items="sortedCountries"
        itemTitle="name"
        itemValue="iso2"
        returnObject
        :theme="dark ? 'dark' : 'light'"
        :disabled="disabled"
        :singleLine="true"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :flat="flat"
        @update:model-value="onChangeCountryCode"
      >
        <template #selection>
          <div
            :class="activeCountry.iso2.toLowerCase()"
            class="vti__flag"
          />
          &nbsp;
        </template>

        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #item="{ item, props: { title, ...rest } }">
          <v-list-item v-bind="rest">
            <div class="country-list-item">
              <div
                :class="item.raw.iso2.toLowerCase()"
                class="vti__flag"
              />
              <div>{{ item.raw.name }} {{ `+${item.raw.dialCode}` }}</div>
            </div>
          </v-list-item>
        </template>
      </v-select>
    </div>

    <v-text-field
      :id="inputId"
      v-model="phone"
      type="tel"
      :messages="messages"
      :disabled="disabled"
      :errorMessages="errorMessages"
      :theme="dark ? 'dark' : 'light'"
      :modelValue="modelValue"
      :rules="rules"
      :density="density"
      :variant="variant"
      :rounded="rounded"
      :flat="flat"
      @update:model-value="onInput"
    />
  </div>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';

import utils, { getCountry } from './vue_tel_input/utils';

function getDefault(key) {
  return utils.options[key];
}

export default {
  name: 'VueTelInputVuetify',

  props: {

    messages: {
      type: [Array, String],
      default: () => [],
    },

    errorMessages: {
      type: [Array, String],
      default: () => [],
    },

    rules: {
      type: Array,
      default: () => [],
    },

    dark: {
      type: Boolean,
      default: false,
    },

    // *** styles:
    density: {
      type: String,
      default: 'compact',
    },

    variant: {
      type: String,
      default: 'filled',
    },

    rounded: {
      type: [Boolean, Number],
      default: false,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: String,
      default: '',
    },

    disabledFetchingCountry: {
      type: Boolean,
      default: () => getDefault('disabledFetchingCountry'),
    },

    disabled: {
      type: Boolean,
      default: () => getDefault('disabled'),
    },

    mode: {
      type: String,
      default: () => getDefault('mode'),
    },

    allCountries: {
      type: Array,
      default: () => getDefault('allCountries'),
    },

    defaultCountry: {
      type: String,
      default: () => getDefault('defaultCountry'),
    },

    preferredCountries: {
      type: Array,
      default: () => getDefault('preferredCountries'),
    },

    onlyCountries: {
      type: Array,
      default: () => getDefault('onlyCountries'),
    },

    ignoredCountries: {
      type: Array,
      default: () => getDefault('ignoredCountries'),
    },

    wrapperClasses: {
      type: [String, Array, Object],
      default: () => getDefault('wrapperClasses'),
    },

    inputId: {
      type: String,
      default: () => getDefault('inputId'),
    },

    inputOptions: {
      type: Object,
      default: () => getDefault('inputOptions'),
    },

  },

  emits: {'update:modelValue': null, 'countryChanged': null, 'open': null, 'close': null, 'validate': null},

  data() {
    return {
      phone: '',
      activeCountry: { iso2: '' },
      open: false,
      finishMounted: false,
      cursorPosition: 0,
      countryCode: null,
    };
  },

  computed: {
    parsedMode() {
      if (this.mode) {
        if (!['international', 'national'].includes(this.mode)) {
          console.error('Invalid value of prop "mode"');
        } else {
          return this.mode;
        }
      }

      if (!this.phone || this.phone[0] !== '+') {
        return 'national';
      }

      return 'international';
    },

    filteredCountries() {
      // List countries after filtered
      if (this.onlyCountries.length) {
        return this.getCountries(this.onlyCountries);
      }

      if (this.ignoredCountries.length) {
        return this.allCountries.filter(
          ({ iso2 }) =>
            !this.ignoredCountries.includes(iso2.toUpperCase()) &&
            !this.preferredCountries.includes(iso2.toLowerCase()),
        );
      }

      return this.allCountries;
    },

    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(this.preferredCountries).map(
        country => ({ ...country, preferred: true }),
      );

      return [...preferredCountries, ...this.filteredCountries];
    },

    phoneObject() {
      const rawResult = parsePhoneNumber(this.phone || '', {
        regionCode: this.activeCountry.iso2,
      });

      const result = {
        ...rawResult,
        isValid: rawResult.valid,
        country: this.activeCountry,
      };

      if (!this.phone) {
        result.number = {
          input: '',
        };
      }

      return result;
    },

    phoneText() {
      let key = 'input';

      if (this.phoneObject.valid) {
        key = this.parsedMode;
      }

      return this.phoneObject.number[key] || '';
    },
  },

  watch: {

    'phoneObject.valid': function (value) {
      if (value) {
        this.phone = this.phoneText;
      }

      this.$emit('validate', this.phoneObject);
    },

    modelValue() {
      this.phone = this.modelValue;
    },

    open(isDropdownOpened) {
      // Emit open and close events
      if (isDropdownOpened) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },

    phone(newValue) {
      if (newValue) {
        if (newValue[0] === '+') {
          const code = parsePhoneNumber(newValue).regionCode;

          if (code) {
            this.activeCountry = this.findCountry(code) || this.activeCountry;
          }
        }
      }

      this.$emit('update:modelValue', this.phoneText, this.phoneObject);
    },

    activeCountry(value) {
      if (value && value.iso2) {
        this.$emit('countryChanged', value);
      }
    },
  },

  mounted() {
    this.initializeCountry()
      .then(() => {
        if (
          !this.phone &&
          this.inputOptions &&
          this.inputOptions.showDialCode &&
          this.activeCountry.dialCode
        ) {
          this.phone = `+${this.activeCountry.dialCode}`;
        }

        this.countryCode = this.activeCountry;
        this.$emit('validate', this.phoneObject);
      })
      .catch(console.error)
      .finally(() => {
        this.finishMounted = true;
      });
  },

  created() {
    if (this.value) {
      this.phone = this.value.trim();
    }
  },

  methods: {
    initializeCountry() {
      return new Promise(resolve => {
        /**
         * 1. If the phone included prefix (+12), try to get the country and set it
         */
        if (this.phone && this.phone[0] === '+') {
          const activeCountry = parsePhoneNumber(this.phone).regionCode;

          if (activeCountry) {
            this.choose(activeCountry);
            resolve();

            return;
          }
        }

        /**
         * 2. Use default country if passed from parent
         */
        if (this.defaultCountry) {
          const defaultCountry = this.findCountry(this.defaultCountry);

          if (defaultCountry) {
            this.choose(defaultCountry);
            resolve();

            return;
          }
        }

        const fallbackCountry =
          this.findCountry(this.preferredCountries[0]) || this.filteredCountries[0];

        /**
         * 3. Check if fetching country based on user's IP is allowed, set it as the default country
         */
        if (!this.disabledFetchingCountry) {
          getCountry()
            .then(res => {
              if (this.phone === '') {
                this.activeCountry = this.findCountry(res) || this.activeCountry;
              }
            })
            .catch(error => {
              console.warn(error);
              /**
               * 4. Use the first country from preferred list (if available) or all countries list
               */
              this.choose(fallbackCountry);
            })
            .finally(() => {
              resolve();
            });
        } else {
          /**
           * 4. Use the first country from preferred list (if available) or all countries list
           */
          this.choose(fallbackCountry);
          resolve();
        }
      });
    },

    /**
     * Get the list of countries from the list of iso2 code
     */
    getCountries(list = []) {
      return list.map(countryCode => this.findCountry(countryCode)).filter(Boolean);
    },

    findCountry(iso = '') {
      return this.allCountries.find(country => country.iso2 === iso.toUpperCase());
    },

    choose(country, toEmitInputEvent = false) {
      this.activeCountry = country || this.activeCountry || {};
      if (
        this.phone &&
        this.phone[0] === '+' &&
        this.activeCountry.iso2 &&
        this.phoneObject.number.significant
      ) {
        // Attach the current phone number with the newly selected country
        this.phone = parsePhoneNumber(this.phoneObject.number.significant, {
          regionCode: this.activeCountry.iso2,
        }).number.international;
      } else if (this.inputOptions && this.inputOptions.showDialCode && country) {
        // Reset phone if the showDialCode is set
        this.phone = `+${country.dialCode}`;
      }

      if (toEmitInputEvent) {
        this.$emit('update:modelValue', this.phoneText, this.phoneObject);
      }
    },

    onInput(e) {
      this.$emit('update:modelValue', this.phoneText, this.phoneObject);
      if (e && e.target) {
        this.cursorPosition = e.target.selectionStart;
      }
    },

    onChangeCountryCode() {
      this.choose(this.countryCode, true);
    },
  },
};
</script>

<style src="./vue_tel_input/sprite.css"></style>

<style lang="scss" scoped>
.vti__flag {
  margin-right: 8px;
}

.country-list-item {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.content {
  display: flex;
  align-items: center;

  .country-code {
    width: 95px;
  }

  li.last-preferred {
    border-bottom: 1px solid #CACACA;
  }

  .v-text-field {

    .v-select__selections {
      position: relative;

      // .vti__flag {
      //   position: absolute;
      //   margin-left: 18px;
      // }
    }

  }
}
</style>
