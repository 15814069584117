<template>
  <div>
    {{ v('firstname') }} {{ v('lastname') }}
  </div>
</template>

<script lang="ts" setup>
import type { CellViewComponentProps } from "@/utils/entity-grid/table-grid-to-entity";

const props = defineProps<
  CellViewComponentProps & {
    startsWith?: string;
  }
>();

function v(fieldName: string): string {
  if (!props.item) return '';

  const field = Object.keys(props.item).find(
    (key) =>
      key.endsWith(`.${fieldName}`) && (!props.startsWith || key.startsWith(props.startsWith)),
  );
  if (!field) return '';

  return String(props.item[field]?.presentable || '');
}


</script>
~/services/table-grid-to-entity
