<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-if="shortenSummory" v-html="shortenSummory" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ParsedFeedItem } from '../../JsonFeed';
const props = defineProps<{ item: ParsedFeedItem }>();

const CHARACTERS_CUT_OFF = 250;

const shortenSummory = computed(() => {
  if (!props.item.summary) return '';
  return props.item.summary.length > CHARACTERS_CUT_OFF
    ? `${props.item.summary.slice(0, CHARACTERS_CUT_OFF)  }...`
    : props.item.summary;
});


</script>
