/* eslint-disable camelcase */
export default {
  account_information: "Информация об аккаунте",
  contact_details: "Контактные данные",
  phone_confirmation: "Подтверждение номера телефона",
  email_confirmation: "Подтверждение электронной почты",

  register: "Зарегистрироваться",
  you_have_registered: "Вы успешно зарегистрированы!",

  agreement_title: "Условия и положения",
  agreement_label: "Я согласен с условиями и положениями",
  agreement_required: "Вы должны согласиться с условиями и положениями",

};
