/* eslint-disable camelcase */
export default {
  buyTicket: 'Купить',
  readMore: 'Читать далее',
  share: 'Поделиться',
  open: 'Открыть',
  back: 'Назад',
  download: 'Скачать',
  seeAll: 'Смотреть все',
  shareNotSupported: 'Ссылка скопирована в буфер обмена!',
};
