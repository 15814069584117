<template>
  <DynamicStepper
    v-bind="stepperProps"
    :inputProperties="resultInputProperties"
    :enableFinalSubmit="true"
    finalSubmitTitle="uiSignupRegistration.register"
    :altLabels="altLabels"
  >
    <v-alert
      type="success"
      class="mb-4"
    >
      <div>{{ $t('uiSignupRegistration.you_have_registered') }}</div>
    </v-alert>
  </DynamicStepper>
</template>

<script setup lang="ts">
import { auth } from '@ui-api3-sdk/api';

import { computed, markRaw, onMounted } from 'vue';
import { SignupRegistrationProps, defaults } from './SignupRegistration.ts';

import { useFormBuilder } from '@/composables';
import { apiErrorToString, useAccountCreationApi, useAccountSearchApi } from '@ui-api3-sdk/api/api3';

import { untilFalse } from '@/utils/reactive-helpers';

import { Step } from '@/models/dynamic-stepper-interface';
import { CONFIRMABLE_FIELDS_ALIASES, ConfirmableFieldAlias, ConfirmationResult } from '@/models/field-confirmation-interface';
import { FormResult, genFormFieldFromAlias } from '@/models/form-builder-interface';

import { useConfirmableField } from '@/composables/useConfirmableField';
import { useDynamicStepper } from '@/composables/useDynamicStepper';
import { useNavigation } from '@/composables/useNavigation';

import { LoginType, PasswordCreationType, useEnrollSettings } from '@/composables/useEnrollSettings';

import { useLocalization } from '@/composables/useLocalization';

import { usePropertiesStore } from '@/pinia/usePropertiesStore';
import { useRuntimeSettingsStore } from '@/pinia/useRuntimeSettingsStore.ts';

import AgreementCheckbox from './base/AgreementCheckbox.vue';
import DynamicStepper from './base/DynamicStepper.vue';
import { LinkBoxProps } from './LinkBox.ts';

const { t } = useLocalization('uiSignupRegistration');

const { isLoading } = useLocalization('fieldConfirmation', [
  'not_unique',
  'error_checking_uniqueness',
]);

const props = withDefaults(defineProps<SignupRegistrationProps>(), {
  inputProperties: undefined,
  fieldTitles: undefined,
  optionalFields: undefined,
  forceFields: undefined,
  steps: () => defaults.steps,
  otpConfirm: () => defaults.otpConfirm,
  autoLogin: () => defaults.autoLogin,
  agreementLinkboxProps: () => defaults.agreementLinkboxProps,
});

const otpHeaders = new Map<ConfirmableFieldAlias, ConfirmationResult>();
const { settings: enrollSettings, inputProperties: resultInputProperties } = useEnrollSettings(props.inputProperties);
const { stepperProps, stepperMethods: stepper } = useDynamicStepper([], onFinalSubmit);

onMounted(async () => {

  await untilFalse(isLoading);

  try {
    await usePropertiesStore().getPfAsync();
  } catch (err) {
    console.warn('Profile fields cant be fetched, dictionaries are impossible');
  }

  assertPropsValid();

  addFormSteps();

  if (props.agreementFiles && props.agreementFiles.length) {
    addAgreementStep();
  }

  addConfirmationSteps();

  updateSponsorField();

});


function getFormValues() : Record<string, any> {
  const profile = stepper.getAllFormsValues();

  Object.keys(profile).forEach( k => {
    if (! profile[k]) delete profile[k];
  } );


  Object.entries(props.forceFields || {}).forEach( ([k, v]) => {
    profile[k] = v;
  });

  return profile;
}

async function onFinalSubmit() {

  const profile = getFormValues();
  ['password', 'password_confirm', 'sponsor_id', 'login'].forEach( a => delete profile[a] );

  const gluedOtpHeaders = Array.from(otpHeaders.values())
    .map( h => h.otpHeader['x-otp-check'] )
    .join('|');

  try {

    let login = getLoginValue();
    const password = getPasswordValue();

    if ( (!login && !idAsLoginMode()) || password === null) {
      console.log('Login:', login, 'Enroll login type:', enrollSettings.value.loginType, 'Password:', password, 'Enroll password type:', enrollSettings.value.passwordCreationType);
      throw new Error('Please check enroll configuration');
    }


    const createResult = await useAccountCreationApi()
      .accountSignupControllerCreateAccount({
        accountSignupReq: {
          login,
          sponsorId: stepper.findFormField('sponsor_id')?.value,
          password,
          profile,
        },
      }, {
        headers: { 'x-otp-check': gluedOtpHeaders },
      });


    if (idAsLoginMode()) {
      login = String((createResult.data.payload.info as any)?.account?.id); // or user.id ?
      if (! login) {
        console.error('No id as login in response:', createResult);
      }
    }

    if (createResult.status === 200) {

      if (props.autoLogin) {

        if (!login || !password)
          throw new Error('Auto login is not possible without login and password specified by user');

        const loginRes = await auth.login(login, password);
        if (loginRes) {
          const gotTo = useRuntimeSettingsStore().getLoginRedirectPath || '/';
          useRuntimeSettingsStore().setLoginRedirectPath('');
          useNavigation().go(gotTo);
          return undefined;
        }
      }

      useNavigation().toLogin();
      return undefined;

    }


  } catch (err: any) {
    return apiErrorToString(err, 'Error creating account');
  }

  return 'Error creating account';
}

function getLoginValue() {

  const mode = enrollSettings.value.loginType;

  switch (mode) {
    case LoginType.ID_AS_LOGIN: return stepper.findFormField('login')?.value;
    case LoginType.CUSTOM_LOGIN: return stepper.findFormField('login')?.value;
    case LoginType.PHONE_AS_LOGIN: return stepper.findFormField('phone')?.value;
    default: return stepper.findFormField('email')?.value;
  }
}

function idAsLoginMode() {
  return enrollSettings.value.loginType === LoginType.ID_AS_LOGIN;
}


function getPasswordValue() {

  const passwordByUser = stepper.findFormField('password');

  if (enrollSettings.value.passwordCreationType === PasswordCreationType.BY_USER) {

    if (!passwordByUser?.value) {
      console.error('Enroll settings have PASSWORD_BY_USER set, but no password provided in form!');
      return null;
    }

    return passwordByUser.value;
  }

  if (passwordByUser?.value) {
    console.error('Enroll settings PASSWORD_AUTO is set, but password provided by user!');
    return null;
  }


  return undefined;

}

async function onFieldConfirmedStepGuard(result: ConfirmationResult) {
  if (result) {
    otpHeaders.set(result.field, result);
  }
  return true;
}

async function onFormSubmitStepGuard(formRes: FormResult, step: Step) {
  if (step.type !== 'FormBuilder') throw new Error('no way');
  const promises = [];


  for (const alias of CONFIRMABLE_FIELDS_ALIASES) {
    if ( formRes[alias] ) {
      const cf = useConfirmableField(alias);
      promises.push(cf.checkUniqueness(formRes[alias], step.form));
    }
  }

  if (! promises.length) return true;

  const results = await Promise.all(promises);
  return ! results.some( r => !r );
}


function addAgreementStep() {

  const componentProps = {
    linkbox: {
      ... defaults.agreementLinkboxProps,
      ...(props.agreementLinkboxProps || {}),
      files: props.agreementFiles,
    } as LinkBoxProps,
    agreeLabel: props.agreementTitle,
    agreeValidationMessage: props.agreementValidationText,

    formFieldsGetter: getFormValues,

  };

  stepper.addStep({
    type: 'CustomComponent',
    title: `uiSignupRegistration.agreement_title`,
    component: markRaw(AgreementCheckbox),
    name: 'agreement',
    componentProps,
    isMandatory: true,
  });

}

function addFormSteps() {
  props.steps.forEach(step => {

    const fields = step.aliases.map(alias => {
      const field = {
        ... genFormFieldFromAlias(alias),
        isMandatory: ! isFieldOptional(alias),
      };
      if (props.fieldTitles?.[alias]) {
        field.title = props.fieldTitles[alias];
      }
      return field;
    });

    stepper.addStep({
      name: step.title,
      type: 'FormBuilder',
      form: useFormBuilder(fields),
      goNextGuard: onFormSubmitStepGuard,
    });

  });

}

function addConfirmationSteps() {
  const fieldsToConfirm = CONFIRMABLE_FIELDS_ALIASES
    .filter(alias => props.otpConfirm[alias])
    .sort( (alias1, alias2) => {
      const a = props.otpConfirm[alias1];
      const b = props.otpConfirm[alias2];
      if (a === b) return 0;
      if (a === 'optional') return -1;
      return 1;
    }); // confirmed fields must come last, optinally confirmed - first

  fieldsToConfirm.forEach(alias => {
    const formField = stepper.findFormField(alias);
    if (! formField) return;

    stepper.addStep({
      type: 'FieldConfirmation',
      name: `${alias}_confirm`,
      title: `uiSignupRegistration.${alias}_confirmation`,
      alias,
      valueRef: computed(() => {
        const val = stepper.findFormField(alias)?.value;
        return val;
      }),
      isMandatory: ! isFieldOptional(alias),
      goNextGuard: onFieldConfirmedStepGuard,
      componentProps: {
        updateProfile: false,
      },
    });
  });

}

function isFieldOptional(alias: string) {
  return props.optionalFields?.includes(alias);
}

async function updateSponsorField() {

  const sponsorField = stepper.findFormField('sponsor_id');
  if (! sponsorField) return;

  const codeInUrl = useNavigation().param('ref');
  const codeInLocalStorage = localStorage.getItem('referral_code');

  if (codeInUrl) {
    localStorage.setItem('referral_code', codeInUrl);
  }

  const inviteCode = codeInUrl || codeInLocalStorage;
  if (! inviteCode) return;

  useAccountSearchApi()
    .getAccountByInviteCode({
      inviteCode,
    })
    .then( res => {
      const id = res.data.payload.idValue.raw;
      const name = res.data.payload.title;

      sponsorField.value = id;
      sponsorField.disabled = true;
      sponsorField.title = `${t('properties_custom.sponsor_id')}: ${name}`;
    })
    .catch( err => {
      console.error('getAccountByInviteCode error:', err);
    });

}

function assertPropsValid() {
  const confirmConfig = props.otpConfirm;
  if (! confirmConfig)
    throw new Error('otpConfirm prop is required');

  const allAliases = props.steps?.flatMap(s => s.aliases) ?? [];
  const hasAlias = (alias: string) => allAliases.includes(alias);

  const needsConfirm = Object.entries(confirmConfig).some(([alias, confMode]) => (confMode === true) && hasAlias(alias) && !isFieldOptional(alias));

  if (! needsConfirm)
    throw new Error('Not even one mandatory confirmable field in props!');
}

</script>
