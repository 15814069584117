<template>
  <div>
    <v-icon
      v-if="valueIsPositive"
      color="green"
    >
      mdi-check
    </v-icon>
    <v-icon
      v-else
      color="red"
    >
      mdi-close
    </v-icon>
  </div>
</template>

<script lang="ts" setup>
import type { CellViewComponentProps } from "@/utils/entity-grid/table-grid-to-entity";
import { computed } from 'vue';


const props = defineProps<CellViewComponentProps>();

const valueIsPositive = computed(() => {

  if (typeof props.value === 'boolean') {
    return props.value;
  }

  if (typeof props.value === 'number') {
    return props.value > 0;
  }

  if (typeof props.value === 'string') {

    if (props.value === '') return false;

    const negative = props.value.toLocaleLowerCase().match(/no|false/) !== null;
    if (negative) return false;

    return true;

  }

  if (props.value === null || props.value === undefined)
    return false;


  return !!props.value;

});

</script>
~/services/table-grid-to-entity
