

import * as d3 from 'd3'; // TODO: import only needed functions
import { TreeNode } from '@/models/tree-interface';
import { Item } from '@/models/table-grid-interface';

const TRANSITION_TIME = 750;
const ZOOM_MIN = 0.2;
const ZOOM_MAX = 4;
const ZOOM_AUTO_MAX = 1;
const ZOOM_AUTO_MIN = 0.2;

export interface TreeRenderParams {
  cardWidth: number;
  cardHeight: number;

  hMargin: number;
  vMargin: number;

  hPadding: number;
  vPadding: number;

  separation?: number;
  initialZoomScale?: number;

  cardCreate?: (nodes: DGroup, cardContent: DGroup, emptyCardContent: DGroup, core: RenderCore) => void;
  cardUpdate?: (nodes: DGroup, cardContent: DGroup, emptyCardContent: DGroup, core: RenderCore, nodeList: DNode[]) => void;
}

export type DGroup = d3.Selection<SVGGElement, d3.HierarchyPointNode<TreeNode>, SVGGElement, unknown>;
export type DNode = d3.HierarchyPointNode<TreeNode>;

export type RenderCore = ReturnType<typeof initRenderCore>;

type ValueFn = d3.ValueFn<d3.BaseType, d3.HierarchyPointNode<TreeNode>, string | number | boolean | null>;
type EventHandler = (this: d3.BaseType, event: any, d: d3.HierarchyPointNode<TreeNode>) => void;
type LinkDNode = any;

interface ScreenState {
  width: number;
  height: number;
}
interface Coordinates {
  x: number;
  y: number;
}

type createButtonsParams = {
  anchorEnd: boolean,
  horizontal: boolean,
}

const icons = {
  arrowDown: "M -8 -2 L 0 6 L 9 -2 L 6 -2 L 0 3 L -5 -2",
  arrowDown3: "M -8 -6 L 0 2 L 9 -6 L 6 -6 L 0 -1 L -5 -6 M -8 2 L 0 10 L 9 2 L 6 2 L 0 7 L -5 2",
  loading: "M 0 8 Q 8 8 8 0 M -8 0 Q -8 -8 0 -8",
  target: `M 0 5 V -5 A 0.45 0.45 90 0 1 0 5 Z A 0.45 0.45 90 0 1 0 -5 Z`,
} as const;

export type Icon = {
  path: [keyof typeof icons] | string,
  rotate?: number,
  style?: string,
  x?: number,
  y?: number,
}

interface CoreButton {
  id: string;
  hidden?: (d: DNode) => boolean,
  text?: ValueFn,
  icon?: (d: DNode) => Icon,
  onClick?: EventHandler,
}

interface CoreTextLine {
  class: string,
  text: string | ((d: DNode) => string),
  fontSize?: number,
  style?: (d: DNode) => string,
}

export function presentableValue(d: DNode, field: string) {
  return String(d.data.item[field]?.presentable);
}

function defaultCardCreate(nodes: DGroup, cardContents: DGroup, emptyCardContents: DGroup, core: RenderCore) {
  nodes
    .select("rect")
    .attr("fill", "#fff")
    .attr("stroke", "#555")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 1.5)
    .attr("rx", 5);

  nodes
    .append("text")
    .attr("style", "font: 10px sans-serif;")
    .attr("text-anchor", "start")
    .attr("x", core.params.hMargin + 10)
    .attr("y", core.params.vMargin + 10 + 10);
}

function defaultCardUpdate(nodes: DGroup, cardContents: DGroup, emptyCardContents: DGroup, core: RenderCore) {
  nodes
    .select('text')
    .text(d => `${d.data.id} [${Math.floor(d.x)}, ${Math.floor(d.y)}]`);
}

function move(x: number, y: number) {
  return ['transform', `translate(${x}, ${y})`] as const;
}

function initRenderCore(width: number, height: number, svgRef: SVGElement, params: TreeRenderParams) {

  const cardTotalWidth = params.cardWidth + params.hMargin * 2;
  const cardTotalHeight = params.cardHeight + params.vMargin * 2;

  let screen: ScreenState | undefined = undefined;
  let nodesList: DNode[] = [];

  const buttons: CoreButton[] = [];

  const svg = d3.select<SVGElement, unknown>(svgRef);
  const g = svg.append("g");
  const gLinks = g.append("g")
    .attr("fill", "none")
    .attr("stroke", "#555")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 2.5);

  const gNodes = g.append("g");

  const tree = d3.tree<TreeNode>()
    .nodeSize([cardTotalWidth, cardTotalHeight])
    .separation((a, b) => a.parent === b.parent ? 1 : params.separation || 1.1);

  const zoom = d3.zoom().scaleExtent([ZOOM_MIN, ZOOM_MAX])
    .on("zoom", zoomed) as any;

  updateScreenSize(width, height);

  svg.call(zoom).call(zoom.transform, createZoomTransform(0, 0, -1));


  function zoomed(this: any, { transform }: { transform: any }) {
    g.attr("transform", transform);
  }

  function updateScreenSize(width: number, height: number) {

    svg.style('height', height);
    svg.style('width', width);

    // svg.attr("viewBox", [0, 0, width, height]);

    screen = { width, height };

  }

  function createZoomTransform(diffX: number, diffY: number, scale?: number) {
    let resultScale = scale || params.initialZoomScale || 1;
    let xOffset = diffX;
    let yOffset = diffY;

    if (resultScale === -1) {
      // fit all network to screen (ignores diffX, diffY)

      if (nodesList.length) {

        const withoutUplineNodes = nodesList.filter(d => ! (Number(d.data.item['t.level']?.raw) < 0));
        // const withoutUplineNodes = nodesList;

        const minX = d3.min(withoutUplineNodes, d => d.x) || 0;
        const maxX = d3.max(withoutUplineNodes, d => d.x) || 0;

        const minY = d3.min(withoutUplineNodes, d => d.y) || 0;
        const maxY = d3.max(withoutUplineNodes, d => d.y) || 0;

        const nodesWidth = (maxX - minX) + cardTotalWidth;
        const nodesHeight = (maxY - minY) + cardTotalHeight;

        const screenScale = Math.min(screen!.width / nodesWidth, screen!.height / nodesHeight);

        resultScale = Math.min(screenScale, ZOOM_AUTO_MAX);
        if (resultScale >= ZOOM_AUTO_MIN) {
          xOffset = (Math.abs(maxX) - Math.abs(minX)) / 2;
        } else {
          xOffset = 0;
          resultScale = ZOOM_AUTO_MIN;
        }

        yOffset = (maxY - minY) / 2 + withoutUplineNodes[0].y;

      } else
        resultScale = 1;

    }

    const centerX = screen!.width / 2 - ( cardTotalWidth / 2 + xOffset ) * resultScale ;
    const centerY = screen!.height / 2 - ( cardTotalHeight / 2  + yOffset ) * resultScale;

    return d3.zoomIdentity
      .translate(
        centerX,
        centerY,
      )
      .scale(resultScale);
  }

  function resetZoom(focusNodeId?: string, scale?: number) {

    if (! screen ) return;

    let toX = 0;
    let toY = 0;

    if ( focusNodeId) {
      const foundNode = nodesList.find(d => d.data.id === focusNodeId);
      if (foundNode) {
        toX = foundNode.x;
        toY = foundNode.y;
      }
    }

    svg.transition()
      .duration(TRANSITION_TIME)
      .call(zoom.transform,
        createZoomTransform(toX, toY, scale));
  }

  function linkPath(s0: Coordinates, d0: Coordinates) {
    const s = { x: s0.x + cardTotalWidth / 2, y: s0.y + params.cardHeight + params.vMargin };
    const d = { x: d0.x + cardTotalWidth / 2, y: d0.y + params.vMargin };

    const controlPoint1 = { x: s.x, y: (s.y + d.y) / 2 };
    const controlPoint2 = { x: d.x, y: (s.y + d.y) / 2 };

    return `M ${s.x} ${s.y} C ${controlPoint1.x} ${controlPoint1.y}, ${controlPoint2.x} ${controlPoint2.y}, ${d.x} ${d.y}`;
  }


  function createAvatar(nodes: DGroup, avatar: (d: DNode) => string, setClass = 'avatar', sx = 0, sy = 0, radius = 30, padding = 0) {

    const radiusWithPadding = radius + padding;

    const gAvatar = nodes.append("g")
      .attr(...move(sx + radiusWithPadding, sy + radiusWithPadding));

    gAvatar
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clipAvatar')
      .append('svg:circle')
      .attr('r', radiusWithPadding)
      .attr('fill', 'none');

    gAvatar
      .append('svg:circle')
      .attr('r', radiusWithPadding)
      .attr('clip-path', 'url(#clipAvatar)')
      .attr('fill', 'rgba(15, 179, 88, 0.1)')
      .attr('part', 'circle');

    gAvatar
      .append('svg:image')
      .attr('y', -radius)
      .attr('x', -radius)
      .attr('width', radius * 2)
      .attr('height', radius * 2)
      .attr('clip-path', 'url(#clipAvatar)')
      .attr('xlink:href', avatar)
      .attr("class", setClass);


    return { width: () => sx + radiusWithPadding * 2, height: () => sy + radiusWithPadding * 2, gAvatar };

  }

  function updateIcon(
    path: d3.Selection<SVGPathElement, d3.HierarchyPointNode<TreeNode>, SVGGElement, unknown>,
    icon: (d: DNode) => Icon,
    transition = false,
  ) {

    const withPath = path
      .attr("d", (d) => icon(d).path)
      .attr("style", (d) => icon(d).style ?? "");

    function withTransition() {
      if (!transition) return withPath;
      return withPath
        .transition()
        .duration(TRANSITION_TIME);
    }

    return withTransition()
      .attr("transform", (d) => {
        const i = icon(d);
        return `translate(${i.x ?? 0}, ${i.y ?? 0}) rotate(${i.rotate ?? 0})`;
      });
  }

  function renderIcon(id: string, g: DGroup, icon: (d: DNode) => Icon) {
    const elementPath = g
      .append("path")
      .attr("class", `icon-${id}`);

    return updateIcon(elementPath, icon);
  }

  function createButtons(nodes: DGroup, sx = 0, sy = 0, params: createButtonsParams = { anchorEnd: false, horizontal: true }) {

    const g = nodes.append("g")
      .attr(...move(sx, sy));

    let curX = 0;
    let curY = 0;

    const addSign = params.anchorEnd ? -1 : 1;

    const methods = { circle, space, lastY: () => curY, lastX: () => curX };

    function space(gap: number) {
      if (params.horizontal) {
        curX += addSign * gap;
      } else {
        curY += addSign * gap;
      }
      return methods;
    }

    function circle(radius: number, button: CoreButton) {

      if (!buttons.some(b => b.id === button.id)) {
        buttons.push(button);
      }

      const cg = g.append("g")
        .attr("class", `button-${button.id}`)
        .attr(...move(curX, curY));

      cg.append('svg:circle')
        .attr('r', radius)
        .attr('fill', 'rgba(255,255,255,1)')
        .attr("stroke", "#555")
        .attr("stroke-opacity", 0.4)
        .attr("stroke-width", 2.5)
        .attr('part', 'circle');

      if (button.text)
        cg.append('svg:text')
          .attr('y', 5)
          .attr('x', 0)
          .attr('text-anchor', 'middle')
          .attr('style', 'font: 10px sans-serif;')
          .text(button.text);

      if (button.icon)
        renderIcon(button.id, cg, button.icon);

      if (button.hidden)
        cg.attr('visibility', (d) => (!button?.hidden?.(d) ? 'visible' : 'hidden'));

      if (button.onClick)
        cg.on('click', button.onClick);

      space(radius * 2);
      return methods;
    }


    return methods;
  }

  function createText(nodes: DGroup, x?: number, y?: number, textAnchor = "start", setMaxWidth?: number) {

    const gText = nodes.append("g")
      .attr(...move(x ?? 0, y ?? 0));

    const maxWidth = setMaxWidth || params.cardWidth - params.hPadding * 2
      - (textAnchor === 'middle' ? 0 : (x || 0));

    let curY = 0;
    let prevFontSize = 10;
    let width = 0;

    const methods = { add, space, height: () => curY, width: () => width };

    function space(height?: number) {
      curY += height ?? Math.floor(prevFontSize / 2);
      return methods;
    }

    function add(p: CoreTextLine, personalMaxWidth?: number) {

      const fontSize = p.fontSize ?? 10;

      curY += fontSize;
      prevFontSize = fontSize;
      const t = gText.append("text")
        .attr("class", p.class)
        .text(p.text)
        .attr("style", (d: DNode) => `font: ${fontSize}px sans-serif; ${p.style?.(d)}`)
        .attr("text-anchor", textAnchor)
        .attr("y", curY)
        .attr("lengthAdjust", "spacingAndGlyphs");

      const resMaxWidth = personalMaxWidth || maxWidth;

      if (typeof p.text === 'string') {
        let textWidth = t.node()?.getComputedTextLength() || 0;

        if (textWidth > resMaxWidth) {
          textWidth = resMaxWidth;
          t.attr("textLength", resMaxWidth);
        }
        width = Math.max(width, textWidth);
      } else {
        t.attr("textLength", (d: DNode, i: number, nodes) => {
          const renderedWidth = nodes[i].getComputedTextLength();
          return Math.min(renderedWidth, resMaxWidth);
        });

      }


      return methods;
    }

    return methods;
  }

  function findItem(nodeId: string) {
    const node = nodesList!.find(n => n.data.id === nodeId);
    if (!node) return undefined;
    return Item(node.data.item);
  }

  function render(data: TreeNode) {

    const root = d3.hierarchy<TreeNode>(data);

    root.sort((a, b) => d3.ascending(
      Number(a.data.item['vmdl.number']?.raw),
      Number(b.data.item['vmdl.number']?.raw)),
    );

    const treeData = tree(root);
    nodesList = treeData.descendants();

    const links = gLinks.selectAll<SVGPathElement, any>(".link")
      .data(root.links(), (d: LinkDNode) => `link-${d.source.data.id}-${d.target.data.id}`);

    links.exit()
      .transition()
      .duration(TRANSITION_TIME)
      .attr("d", (d: LinkDNode) => {
        const goToNode = findAliveParent(d.target, nodesList);
        return linkPath(XY(goToNode), XY(goToNode));
      })
      .style("opacity", 0)
      .remove();

    const linksEnter = links.enter()
      .append("path")
      .attr("class", "link")
      .attr("d", (d: LinkDNode) => {
        const src = parentElementXY(d.target);
        return linkPath(src, src);
      });

    linksEnter
      .style("opacity", 0);

    linksEnter.merge(links)
      .transition()
      .duration(TRANSITION_TIME)
      .attr("d", (d: LinkDNode) => linkPath(d.source, d.target))
      .style("opacity", 1);

    const nodes = gNodes.selectAll<SVGGElement, any>(".node")
      .data(nodesList, (d: DNode) => d.data.id);

    nodes.exit()
      .transition()
      .duration(TRANSITION_TIME)
      .style("opacity", 0) // Set opacity to 0 on exit
      .attr("transform", (d: any) => position(findAliveParent(d, nodesList)))
      .remove();

    const nodeEnter = nodes.enter()
      .append("g")
      .attr("class", "node")
      .attr("id", d => `card-${d.data.id}`)
      .attr("transform", (d: DNode) => position(d.parent, true))
      .style("opacity", 0);

    nodeEnter
      .append("rect")
      .attr("width", params.cardWidth)
      .attr("height", params.cardHeight)
      .attr("x", params.hMargin)
      .attr("y", params.vMargin);

    const cardContents = nodeEnter
      .append("g")
      .attr('transform', `translate(${params.hMargin + params.hPadding}, ${params.vMargin + params.vPadding})`);

    cardContents
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clipCard')
      .append('svg:rect')
      .attr("width", params.cardWidth - params.hPadding * 2)
      .attr("height", params.cardHeight - params.vPadding * 2)
      .attr("x", 0)
      .attr("y", 0);

    const cardClippedContent = cardContents
      .append('g')
      .attr("class", "card-content")
      .attr('clip-path', 'url(#clipCard)');

    const emptyCardClippedContent = cardContents
      .append('g')
      .attr("class", "card-empty-content")
      .attr('clip-path', 'url(#clipCard)');

    const createHandler = params.cardCreate || defaultCardCreate;
    createHandler(nodeEnter, cardClippedContent, emptyCardClippedContent, core);

    const nodeUpdate = nodes.merge(nodeEnter as any);

    const updateHandler = params.cardUpdate || defaultCardUpdate;
    updateHandler(nodeUpdate, cardClippedContent, emptyCardClippedContent, core, nodesList);

    buttons.forEach(b => {
      if (b.icon) {
        const icons = nodeUpdate.selectAll<SVGPathElement, any>(`.icon-${b.id}`)
          .data(d => d, (d: DNode) => `icon-${b.id}-${d.data.id}`);

        updateIcon(icons, b.icon, true);
      }

      if (b.hidden) {
        // todo: if more that one paramets in button is needed to be changable
        // create updateButton function as for icons.
        const buttons = nodeUpdate.selectAll<SVGGElement, any>(`.button-${b.id}`)
          .data(d => d, (d: DNode) => `buttons-${b.id}-${d.data.id}`);

        buttons
          .attr('visibility', (d) => {
            const res = (!b.hidden?.(d) ? 'visible' : 'hidden');
            return res;
          });
      }

    });


    nodeUpdate.selectAll<SVGGElement, DNode>('.card-content')
      .data(d => d, (d) => `card-content-${d.data.id}`)
      .attr('visibility', (d) => (!d.data.isEmpty ? 'visible' : 'hidden'));

    nodeUpdate.selectAll<SVGGElement, DNode>('.card-empty-content')
      .data(d => d, (d) => `card-empty-content-${d.data.id}`)
      .attr('visibility', (d) => (d.data.isEmpty ? 'visible' : 'hidden'));

    nodeUpdate
      .transition()
      .duration(TRANSITION_TIME)
      .attr("transform", (d: DNode) => `translate(${d.x},${d.y})`)
      .style("opacity", 1);

  }


  const core = {
    render,
    findItem,

    updateScreenSize,
    resetZoom,

    createAvatar,
    createText,
    createButtons,

    icons,
    params,
  };

  return core;
}

function getElementXY(id: string): Coordinates | undefined {

  const element = document.getElementById(`card-${id}`);
  if (!element) return undefined;

  const transformAttributeValue = element.getAttribute("transform");
  if (!transformAttributeValue) return undefined;

  const translateRegex = /translate\(\s*([^\s,]+)[\s,]+([^\s,]+)\s*\)/;
  const match = transformAttributeValue.match(translateRegex);

  if (match && match.length === 3) {
    const x = parseFloat(match[1]);
    const y = parseFloat(match[2]);

    return { x, y };
  }
  return undefined;
}

function elementXY(d?: DNode | null): Coordinates {
  if (!d) return { x: 0, y: 0 };
  const elementCoords = getElementXY(d.data.id);
  if (elementCoords) return elementCoords;
  return { x: 0, y: 0 };
}

function parentElementXY(d: DNode): Coordinates {
  if (!d.parent) return { x: 0, y: 0 };
  const elementCoords = getElementXY(d.parent.data.id);
  if (elementCoords) return elementCoords;
  return parentElementXY(d.parent);
}

function XY(d?: DNode | null): Coordinates {
  if (!d) return { x: 0, y: 0 };
  return { x: d.x, y: d.y };
}

function position(d?: DNode | null, fromElement = false) {
  const { x, y } = (fromElement ? elementXY(d) : XY(d));
  const res = `translate(${x},${y})`;
  return res;
}

function findAliveParent(node: DNode | null | undefined, nodes: DNode[]) {

  const parent = node?.parent;
  if (!parent) return undefined;

  const openParent = nodes.find(d => d.data.id === parent.data.id);
  if (openParent) {
    // console.log('foundParent node=', node.data.id, ' parent=', parent.data.id, ' foundParent=', openParent.data.id);
    return openParent;
  }

  return findAliveParent(parent, nodes);
}


export function useTreeRender(params: TreeRenderParams) {

  let core: RenderCore | undefined = undefined;

  function init(width: number, height: number, svgRef: SVGElement) {
    core = initRenderCore(width, height, svgRef, params);
    return core;
  }

  function getCore() {
    return core;
  }

  return { getCore, init };
}
