<template>
  <EntityGridLite
    v-if="queryParams"
    :useColumns="useColumns"
    :entityName="entityName"
    :columnsOverride="overrideColumns"
    :editUsingRoute="true"
    deleteMode="none"
    :queryOverrides="queryParams"
    :useExpandbleRows="true"
  >
    <template #expanded="slotParams">
      <PosOrder :order="slotParams.item.srcItem.raw" />
    </template>

    <template #[safeSlotName(`posDocument.account.user.lastname`)]="slotParams">
      <CellUser
        :item="slotParams.item.getRaw()"
        startsWith="posDocument.account.user"
      />
    </template>

    <template #[safeSlotName(`posDocument.totalItemPrice`)]="slotParams">
      <CellPrice
        :value="slotParams.value"
        :item="slotParams.item.getRaw()"
      />
    </template>

    <template #[safeSlotName(`posDocument.paidTotal`)]="slotParams">
      <CellPrice
        :value="slotParams.value"
        :item="slotParams.item.getRaw()"
      />
    </template>

    <template #[safeSlotName(`posDocument.creatorAdmin.lastname`)]="slotParams">
      <CellUser
        :item="slotParams.item.getRaw()"
        startsWith="posDocument.creatorAdmin"
      />
    </template>

    <template #top-left />
  </EntityGridLite>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { useAccountId, useLocalization } from '@/composables';
import { safeSlotName } from '@/utils/entity-grid/entity-grid-utils';
import { TableGridColumn } from '@/utils/entity-grid/table-grid-interface';

import CellDocumentStatus from '../pos-entity-lib/CellDocumentStatus.vue';
import CellJsonChips from '../pos-entity-lib/CellJsonChips.vue';
import CellPrice from '../pos-entity-lib/CellPrice.vue';
import CellUser from '../pos-entity-lib/CellUser.vue';
import EntityGridLite from '../pos-entity-lib/EntityGridLite.vue';
import PosOrder from './base/PosOrder.vue';

const { t } = useLocalization();

const entityName = 'PosDocument';

const DocumentTypeId = {
  Adjustment: 1,
  Purchase: 2,
  Return: 3,
  Transfer: 4,
  Order: 5,
  Dispatch: 6,
};

const { aId } = useAccountId();

const queryParams = computed(() => (aId.value
  ? {
    queryName: 'accountPosDocument',
    additionalInputParams: {
      accountId: aId.value,
    },
  }
  : undefined
));


const overrideColumns: Partial<TableGridColumn>[] = [
  {
    name: 'posDocument.properties',
    viewComponent: CellJsonChips,
    filterMode: 'none',
    hidden: 'by_default',
    sortable: false,
  },
  {
    name: 'posDocument.documentStatus.title',
    title: t('model_posDocument.columnStatus'),
    viewComponent: CellDocumentStatus,
    sortable: false,
  },
  {
    name: 'posDocument.documentStatus.color',
    hidden: 'always',
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.documentStatusId',
    hidden: 'always',
    sortable: false,
    // filterMode: 'outside',
    // filterComponent: FilterEntitySelect,
    // filterComponentProps: {
    //   clearable: true,
    //   entityName: 'PosDocumentStatus',
    //   additionalColumns: ['posDocumentStatus.documentTypeId'],
    //   filters: [{
    //     field: 'posDocumentStatus.documentTypeId',
    //     operation: FilterOperationsTypeDefinition.Eq,
    //     value: DocumentTypeId.Order,
    //   }],
    // },
  },
  {
    name: 'posDocument.documentTypeId',
    hidden: 'always',
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.creatorAdminId',
    hidden: 'always',
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.account.id',
    hidden: 'always',
    sortable: false,
    // title: t('model_posDocument.buyer'),
    // filterMode: 'outside',
    // filterComponent: FilterAccountId,
    // filterComponentProps: {
    //   clearable: false,
    // },
  },
  {
    name: 'posDocument.account.user.firstname',
    hidden: 'always',
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.account.user.lastname',
    title: t('model_posDocument.buyer'),
    filterMode: 'none',
    sortable: false,
  },

  {
    name: 'posDocument.totalItemQuantity',
    title: t('model_posDocument.columnTotalItems'),
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.totalItemPrice',
    title: t('model_posDocument.columnTotalPrice'),
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.totalItemDiscount',
    title: t('model_posDocument.columnDiscount'),
    hidden: 'by_default',
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.sourcePosId',
    hidden: 'always',
    filterMode: 'none',
    sortable: false,
  },
  {
    name: 'posDocument.createdAt',
    sortable: false,
    // filterMode: 'inside',
    // filterComponent: FilterDateRange,
    // title: t('model_posDocument.columnCreatedAt'),
    // viewComponentProps: { format: 'onlyDate' },
  },
  {
    name: 'posDocument.id',
    hidden: 'always',
    sortable: true,
  },
  {
    name: 'posDocument.number',
    title: t('model_posDocument.documentId'),
  },
  {
    name: 'posDocument.currencyId',
    // filterComponent: FilterEntitySelect,
    // filterComponentProps: {
    //   entityName: 'Currency',
    //   clearable: true,
    // },
    // filterMode: currencies.length > 1 ? 'outside' : 'none',
    hidden: 'always',
    sortable: false,
  },
  {
    name: 'posDocument.paidTotal',
    sortable: false,
    // title: t('model_posDocument.columnPaymentStatus'),
    // sortable: false,
    // viewComponent: CellPaymentProgress,
    // filterComponent: FilterDocumentPaidStatus,
    // filterComponentProps: {
    //   clearable: true,
    // },
    // filterMode: 'outside',
  },
  {
    name: 'posDocument.paidTotalFromWallet',
    hidden: 'by_default',
    title: t('model_posDocument.columnWalletPayment'),
    sortable: false,
  },
  {
    name: 'posDocument.creatorAdmin.firstname',
    hidden: 'always',
    sortable: false,
  },
  {
    name: 'posDocument.creatorAdmin.lastname',
    title: t('model_posDocument.columnCreator'),
    hidden: 'by_default',
    sortable: false,
  },
  {
    name: 'posDocument.isPaid',
    hidden: 'by_default',
    sortable: false,
  },
  {
    name: 'posDocument.isArchived',
    hidden: 'by_default',
    sortable: false,
  },
  {
    name: 'posDocument.notice',
    hidden: 'by_default',
    sortable: false,
  },
  {
    name: 'posDocument.sourcePos.title',
    title: t('model_posDocument.columnSourcePos'),
    sortable: false,
  },
];


const useColumns = [
  'posDocument.id',                        // ID
  'posDocument.number',                    // Число
  'posDocument.createdAt',                 // Создан в

  'posDocument.totalItemQuantity',

  'posDocument.totalItemPrice',

  'posDocument.paidTotal',
  'posDocument.paidTotalFromWallet',

  'posDocument.notice',
  'posDocument.documentStatus.title',

  'posDocument.documentTypeId',            // Тип документа
  'posDocument.documentStatusId',          // Текущий статус
  'posDocument.creatorAdminId',          // Создано
  'posDocument.isPaid',                    // Оплачено
  'posDocument.properties',                // Свойства Маркетинга
  'posDocument.isArchived',                // Заархивировано

  'posDocument.currencyId',

  'posDocument.documentStatus.color',

  'posDocument.account.id',
  'posDocument.account.user.firstname',
  'posDocument.account.user.lastname',


  'posDocument.totalItemDiscount',

  'posDocument.sourcePosId',


  'posDocument.creatorAdmin.firstname',
  'posDocument.creatorAdmin.lastname',

  'posDocument.sourcePos.title',

] as Array<string>;

</script>
