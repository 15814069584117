<template>
  <div class="text-no-wrap">
    <strong>{{ Number(value).toFixed(2) }}</strong>
    <span
      v-if="currency && currency !== 'null'"
      class="text-caption text-grey-darken-2"
    >&nbsp;{{ currency }}</span>
  </div>
</template>

<script lang="ts" setup>
import { CellViewComponentProps } from '@/utils/entity-grid/table-grid-to-entity';

const props = defineProps<
  CellViewComponentProps & {
    currencyId?: string;
  }
>();

const currency = props.currencyId || v('currencyId');

function v(fieldName: string): string {
  if (!props.item) return '';

  const field = Object.keys(props.item).find( (key) => key.endsWith(`.${fieldName}`) );

  if (!field) return '';

  return String(props.item[field]?.presentable || '');
}


</script>
