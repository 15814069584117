<template>
  <TableGrid
    v-bind="tableProps"
    :autoSwitchMode="autoSwitchMode"
    :columnActions="setupTableColumnActions(props)"
    :paginations="props.paginations"
  >
    <template #top>
      <Filter
        class="flex-1-1"
        :inputProperties="inputProperties"
        :fields="filterInputFields"
        :disabled="tableProps.loading"
        @set-filter="onSetFilter"
      />

      <PeriodSelect
        v-if="selectPeriods"
        v-model="selectedPeriod"

        class="flex-1-1"
        :inputProperties="inputProperties"
      />

      <AccountSearch
        v-model="selectedAccount"
        :networkSearch="false"
        class="flex-1-1"
        :inputProperties="inputProperties"
        :showDisabledAccounts="showDisabledAccounts"
      />

      <FrontlineSelect
        v-if="selectLegsFromTree !== undefined"
        v-model="selectedFrontlinePosition"
        :label="selectLegsLabel"
        :limit="selectLegsLimit"
        class="flex-1-1"
        :inputProperties="inputProperties"
        :accountId="forAccountIdBeforeFrontline"
        :treeId="selectLegsFromTree"
        :positionTitles="selectLegsPositionTitles"
      />

      <v-btn
        variant="text"
        @click="csvExport"
      >
        <v-icon>
          mdi-download-box
        </v-icon>
        CSV
      </v-btn>
    </template>

    <template
      v-for="(prop) in usedProperties.properties"
      #[`${prop.toLowerCase()}`]="slotParams"
      :key="prop"
    >
      <slot :name="prop.toLowerCase()" v-bind="slotParams">
        {{ slotParams.p(prop) }}
      </slot>
    </template>
  </TableGrid>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { TableGridItem, TableGridPaginationState } from "@/models/table-grid-interface";
import { AccountSearchItem } from '@/utils/account-search-view';

import { useAccountId, useLocalization, useTableGrid } from '@/composables';
import { fetchDownline } from '@/utils/fetch-downline-service';

import { FilterItem } from '@ui-api3-sdk/api/api3';
import { useProperties } from '@/composables/useProperties';
import { setupTableColumnActions } from '@/composables/useTableColumnActions';
import { UIFilterItem } from '@/models/filter-interface';
import { TreePosition } from '@/models/tree-interface';
import { getColorScheme } from '@/utils/conditional-colors';
import { isBackendProperty } from '@/utils/properties-mapping-service';

import AccountSearch from './base/AccountSearch.vue';
import Filter from './base/Filter.vue';
import FrontlineSelect from './base/FrontlineSelect.vue';
import PeriodSelect from './base/PeriodSelect.vue';
import TableGrid from './base/TableGrid.vue';

import { DownlineTableProps, defaults } from './DownlineTable.ts';
import { PeriodSelectItem } from './base/PeriodSelect.ts';

const { la } = useLocalization('uiDownlineTable');

const props = withDefaults(defineProps<DownlineTableProps>(),{
  id: undefined,
  headerTitles: undefined,
  inputProperties: undefined,
  selectLegsFromTree: undefined,
  columnActions: undefined,
  showInactive: undefined,
  paginations: () => defaults.paginations,
  autoSwitchMode: () => defaults.autoSwitchMode,
  treeId: () => defaults.treeId,
  hideSelf: () => defaults.hideSelf,
  selectLegsLabel: () => defaults.selectLegsLabel,
  selectLegsLimit: () => defaults.selectLegsLimit,
  selectLegsPositionTitles: () => defaults.selectLegsPositionTitles,
  colors: () => defaults.colors,
  popupInfoOnField: () => defaults.popupInfoOnField,
});

const usedProperties = useProperties(
  props.properties,
  [
    's.id',
    'r.id',
    'vmdl.level',
    'vmdl.childrenCount',
    'p.avatar_id',
    'p.firstname',
    'p.lastname',
  ],
  props.headerTitles,
);

const { tableProps, tableMethods: { setLoading, doFetch, csvExport } } = useTableGrid({
  properties: usedProperties.properties,
  requestProperties: usedProperties.allFetchableProperties(),
  headerTitles: props.headerTitles,
  fetch,
  unknownErrorMessage: la('unknown_error'),
});

const currentFilter = ref<FilterItem[]>([]);

const selectedAccount = ref<AccountSearchItem | undefined>();
const selectedPeriod = ref<PeriodSelectItem | undefined>();
const selectedFrontlinePosition = ref<TreePosition | undefined>();
const showDisabledAccounts = computed(() => props.showInactive === 'only_disabled' || props.showInactive === 'all_inactive')

const { aId: myAccountId } = useAccountId();

const forAccountIdBeforeFrontline = computed(() => {
  const selectedAccountId = selectedAccount.value?.accountId ? Number(selectedAccount.value.accountId) : undefined;
  return selectedAccountId ? selectedAccountId : myAccountId?.value;
});

const forOffset = computed(() => {
  if ( selectedFrontlinePosition.value )
    return selectedFrontlinePosition.value.offset || 1;
  return 1;
});

const forAccountId = computed(() => {
  if ( selectedFrontlinePosition.value?.accountId )
    return selectedFrontlinePosition.value.accountId;
  return forAccountIdBeforeFrontline.value;
});

const filterInputFields = computed(() =>
  props.properties.map((property) => {
    const pType = usedProperties.propertyTypes.value?.[property];
    return {
      value: property,
      text: pType?.title,
      fieldType: pType?.fieldType || 'string',
    } as UIFilterItem;
  }).filter((f) => isBackendProperty(f.value)),
);

function onSetFilter(filter: FilterItem[]) {
  currentFilter.value = filter;
  doFetch();
}

watch( [selectedAccount, selectedPeriod, selectedFrontlinePosition], ()  => {
  doFetch();
});


async function fetch(properties: string[], pagination: TableGridPaginationState) {
  setLoading(true);

  let tableItems: TableGridItem[] = [];
  let totalItems = 0;

  if (! forAccountId.value ) {
    setLoading(false);
    console.error('No our account id on fetch');
    return { tableItems, totalItems };
  }

  const updatedPagination = { ... pagination };
  if (! updatedPagination.orderBy || Object.keys(updatedPagination.orderBy).length === 0 ) {
    updatedPagination.orderBy = {
      'position.tree_level': 'ASC',
      's.id': 'ASC',
    };
  }

  const result =
    await fetchDownline({
      treeId: props.treeId || 0,
      accountId: forAccountId.value || 0,
      offset: forOffset.value,
      properties,
      periodId: selectedPeriod.value?.id,
      filter: currentFilter.value,
      pagination: updatedPagination,
      showInactive: props.showInactive,
    });

  totalItems = result.totalItems;
  tableItems =  result.tableItems.map( (i: TableGridItem) => ({
    ... i,
    rowColor: getColorScheme(i, props.colors),
  } as TableGridItem),
  );

  if (props.hideSelf ) {
    // todo: this should be done at backend otherwise totalItems is unpredictable
    const foundMe = tableItems.find( i => i['s.id']?.raw === String(forAccountId.value) );
    if (foundMe) {
      tableItems = tableItems.filter( i => i !== foundMe );
      totalItems--;
    }
  }

  setLoading(false);
  return { tableItems, totalItems };
}

</script>
