<template>
  <div class="pa-md-4">
    <v-card class="pa-4 d-flex flex-column justify-space-between" style="height: 100%">
      <div>
        <MediaPreview :item="item" />
        <PostHeader :item="item" />
      </div>
      <PostSummary class="mt-2" :item="item" />

      <div class="mt-4 d-flex justify-center">
        <BtnOpen class="ml-2" :itemId="item.id" @open-item="emit('openItem', item.id)" />
      </div>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import { useDisplay } from 'vuetify';
import { ParsedFeedItem } from '../../JsonFeed';

import BtnOpen from '../parts/BtnOpen.vue';
import MediaPreview from '../parts/MediaPreview.vue';
import PostHeader from '../parts/PostHeader.vue';
import PostSummary from '../parts/PostSummary.vue';

defineProps<{ item: ParsedFeedItem }>();

const emit = defineEmits<{
  (event: 'openItem', id: string): void;
}>();

const { xs } = useDisplay();
</script>
