<template>
  <div class="countdown">
    <slot
      name="default"
      :currentPeriodLabel="currentPeriodLabel"
      :timeLeft="timeLeft"
      :periods="periods"
      :calculatedTargetDate="calculatedTargetDate"
      :calculatedPeriodStartDate="calculatedPeriodStartDate"
      :periodicality="periodicality"
    />
    <div class="countdown-wrapper">
      <div v-if="!hideDays" class="countdown-digit">
        <span class="countdown-time">{{ timeLeft.days }}</span>
        <span class="countdown-text">{{ lt('days') }}</span>
      </div>
      <div class="countdown-digit">
        <span class="countdown-time">{{ timeLeft.hours }}</span>
        <span class="countdown-text">{{ lt('hours') }}</span>
      </div>
      <div class="countdown-digit">
        <span class="countdown-time">{{ timeLeft.minutes }}</span>
        <span class="countdown-text">{{ lt('minutes') }}</span>
      </div>
      <div v-if="!hideSeconds" class="countdown-digit">
        <span class="countdown-time">{{ timeLeft.seconds }}</span>
        <span class="countdown-text">{{ lt('seconds') }}</span>
      </div>
    </div>
    <slot
      name="below"
      :currentPeriodLabel="currentPeriodLabel"
      :timeLeft="timeLeft"
      :periods="periods"
      :calculatedTargetDate="calculatedTargetDate"
      :calculatedPeriodStartDate="calculatedPeriodStartDate"
      :periodicality="periodicality"
    />
  </div>
</template>

<script setup lang="ts">
import { useLocalization } from '@/composables';
import { removeDuplicatesById } from '@/utils/utils';
import { PeriodVM, usePeriodApi } from '@ui-api3-sdk/api/api3';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { type CountdownTimerProps, defaults } from './CountdownTimer';

// TODO: this is here for one week till model changes go to production api3
type MyPeriodItem = PeriodVM & { periodicity: 'weekly' | 'monthly' | 'daily' };

const { lt, d } = useLocalization('uiCountdownTimer');

interface TimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  total: number;
}

const props = withDefaults(
  defineProps<CountdownTimerProps>(),
  {
    hideDays: () => defaults.hideDays,
    hideSeconds: () => defaults.hideSeconds,
  },
);

const timeLeft = ref<TimeLeft>({
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
  total: 0,
});

const timerInterval = ref<number>();
const loading = ref(false);
const periods = ref<Array<MyPeriodItem>>([]);

const periodicality = computed(() => {
  if (props.customPeridiocity)
    return props.customPeridiocity;

  const activePeriod = periods.value.find(p => p.is_active);
  if (!activePeriod) return 'monthly';

  return activePeriod.periodicity || 'monthly';
});

const calculatedPeriodStartDate = computed<Date>(() => {
  let currentPeriodStart = new Date();

  if (props.customPeriodStart && props.customPeridiocity) {
    currentPeriodStart = calcCustomCurrentPeriodStartDate(props.customPeriodStart);
  } else {
    const activePeriod = periods.value.find(p => p.is_active);
    if (!activePeriod) return new Date();
    currentPeriodStart = new Date(activePeriod.start_date);
  }
  return currentPeriodStart;
});

const calculatedTargetDate = computed<Date>(() => {

  if (props.target) {
    return new Date(props.target);
  }

  const currentPeriodEndDate = new Date(calculatedPeriodStartDate.value);

  if (periodicality.value === 'monthly') {
    currentPeriodEndDate.setMonth(currentPeriodEndDate.getMonth() + 1);
  } else if (props.customPeridiocity === 'weekly') {
    currentPeriodEndDate.setDate(currentPeriodEndDate.getDate() + 7);
  } else {
    currentPeriodEndDate.setDate(currentPeriodEndDate.getDate() + 1);
  }

  return currentPeriodEndDate;
});


const currentPeriodLabel = computed(() => {
  if (periodicality.value !== 'monthly') return '';

  let now = new Date();
  const periodEndDay = calculatedTargetDate.value.getDate();

  if (
    now.getDate() < periodEndDay ||
    (now.getDate() === periodEndDay && firstDateHoursMoreThanSecondDateHours(calculatedTargetDate.value, now) )
  ) {
    now = subtractOneMonth(now);
  }

  const monthTxt = lt(`month${now.getMonth()}`);
  const year = now.getFullYear();

  return `${monthTxt} ${year}`;
});


function subtractOneMonth(date: Date) {
  // this is needed when in current date is 31 and we need to go to 30th of previous month for example
  const d = new Date(date);
  d.setDate(1);
  d.setMonth(d.getMonth() - 1);
  const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
  d.setDate(Math.min(date.getDate(), lastDay));
  return d;
}

function firstDateHoursMoreThanSecondDateHours(datetime1: Date, datetime2: Date) {
  const hoursPartMillis = (d: Date) => d.getHours() * 60 * 60 * 1000
    + d.getMinutes() * 60 * 1000
    + d.getSeconds() * 1000
    + d.getMilliseconds();

  const hoursPart1 = hoursPartMillis(datetime1);
  const hoursPart2 = hoursPartMillis(datetime2);

  return hoursPart1 > hoursPart2;
}


function calcCustomCurrentPeriodStartDate(customPeriodStart: string) {

  const currentPeriodStart = new Date();
  const now = new Date();

  const firstPeriodStart = new Date(customPeriodStart);
  const firstPeriodMonthDay = firstPeriodStart.getDate();
  const firstPeriodWeekDay = firstPeriodStart.getDay();
  const firstPeriodHour = firstPeriodStart.getHours();

  currentPeriodStart.setHours(firstPeriodHour, 0, 0, 0);

  if (props.customPeridiocity === 'monthly') {
    currentPeriodStart.setDate(firstPeriodMonthDay);
    if (currentPeriodStart.getTime() > now.getTime()) {
      currentPeriodStart.setTime(subtractOneMonth(currentPeriodStart).getTime());
    }
  } else if (props.customPeridiocity === 'weekly') {
    while (currentPeriodStart.getDay() !== firstPeriodWeekDay) {
      currentPeriodStart.setDate(currentPeriodStart.getDate() - 1);
    }
    if (currentPeriodStart.getTime() > now.getTime()) {
      currentPeriodStart.setDate(currentPeriodStart.getDate() - 7);
    }
  } else {
    if (currentPeriodStart.getTime() > now.getTime()) {
      currentPeriodStart.setDate(currentPeriodStart.getDate() - 1);
    }
  }

  return currentPeriodStart;

}


function calculateTimeLeft() {

  const now = new Date();
  const target = calculatedTargetDate.value;
  const difference = target.getTime() - now.getTime();

  if (difference <= 0) {
    timeLeft.value = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      total: 0,
    };
    return;
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  timeLeft.value = {
    days: days.toString(),
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: seconds.toString().padStart(2, '0'),
    total: difference,
  };
}

async function fetchPeriods() {
  function parsePeriods(list: Array<MyPeriodItem>) {
    return removeDuplicatesById(list).map((p, index) => ({
      ...p,
      label: periodName(p),
      index,
    }));
    function periodName(p: MyPeriodItem) {
      const start = d(new Date(p.start_date), 'short');
      return `${start}`;
    }
  }

  const res = await usePeriodApi().getPeriods({
    page: 0,
    limit: 3,
  });

  return parsePeriods(res.data.payload.list as MyPeriodItem[]);
}

onMounted(() => {

  if (props.target || (props.customPeriodStart && props.customPeridiocity)) {
    calculateTimeLeft();
    timerInterval.value = window.setInterval(calculateTimeLeft, 1000);
    return;
  }

  loading.value = true;

  fetchPeriods()
    .then(res => {
      periods.value = res;

      calculateTimeLeft();
      timerInterval.value = window.setInterval(calculateTimeLeft, 1000);
    })
    .finally(() => {
      loading.value = false;
    });
});

onBeforeUnmount(() => {
  if (timerInterval.value) {
    clearInterval(timerInterval.value);
  }
});
</script>

<style scoped>
.countdown {
  display: inline-block;
  margin-top: 20px;
  font-family: sans-serif;
  text-align: center;
}

.countdown-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  font-size: 30px;
}

.countdown-digit {
  display: inline-block;

  padding: 10px;

  color: #525252;

  background: #f6f6f6;
  border-radius: 3px;
}

.countdown-time {
  display: inline-block;

  min-width: 2ch;
  padding: 15px;

  background: #f6f6f6;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}

.countdown-text {
  display: block;
  padding-top: 5px;
  font-size: 16px;
  color: #cccccc;
}
</style>
