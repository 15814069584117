
/* eslint-disable camelcase */
export default {
  month0: 'Январь',
  month1: 'Февраль',
  month2: 'Март',
  month3: 'Апрель',
  month4: 'Май',
  month5: 'Июнь',
  month6: 'Июль',
  month7: 'Август',
  month8: 'Сентябрь',
  month9: 'Октябрь',
  month10: 'Ноябрь',
  month11: 'Декабрь',

  days: 'Дней',
  hours: 'Часов',
  minutes: 'Минут',
  seconds: 'Секунд',

};
