
import { AccountVM } from '@ui-api3-sdk/api/api3';
import { FieldErrors, FormField, FormResult, genFormFieldFromAlias } from '@/models/form-builder-interface';
import { reactive } from 'vue';
import { useLocalization } from './useLocalization';

export type { FormField, FieldErrors, FormResult };

// todo: we need ref link to the FormBuilder component here
// to know when triggering events through formProp.triggers
// that they are actually handled by the component (component not unmounted)
// (resetForm situation in FeedBack).
// or remove all logic from component and move it here, thus making
// FormBuilder component totally not usable without this composable.

export const useFormBuilder = (fields: FormField[]) => {

  const { t, te } = useLocalization();

  const formProps = reactive({
    modelValue: [] as FormField[],
    fieldErrors: {} as FieldErrors,
    errorMessages: [] as string[],
    loading: false,
    isSent: false,
    submitTrigger: false,
    validateTrigger: false,
    resetTrigger: false,
    onFieldUpdated: clearErrors,
    onWasReset: clearErrors,
  });

  if (fields.length) setFields(fields);

  function submit() {
    formProps.submitTrigger = !formProps.submitTrigger;
  }

  function setFields(fields: FormField[] | string[]) {
    if (typeof fields[0] === 'string') {
      fields = fields.map((f) => genFormFieldFromAlias(f as string));
      formProps.modelValue = [...fields];
    }
    formProps.modelValue = [...fields as FormField[]];
  }

  function cleanFieldErrors(alias: string) {
    if (formProps.fieldErrors?.[alias]) {
      delete formProps.fieldErrors[alias];
    }
  }

  function clearErrors() {
    formProps.errorMessages = [];
    formProps.fieldErrors = {};
  }

  function hasErrors() {
    return formProps.errorMessages.length > 0 || Object.keys(formProps.fieldErrors).length > 0;
  }

  function reset() {
    formProps.resetTrigger = !formProps.resetTrigger;
    formProps.modelValue.forEach(field => {
      if (field.fieldType === 'date') field.value = null;
      else
        field.value = undefined;
    });
    formProps.isSent = false;
  }

  function validate() {
    formProps.validateTrigger = !formProps.validateTrigger;
  }


  const parseApiValidation = (axiosError: any) => {
    const base = axiosError?.response?.data?.error;
    if (base?.type === 'validation') {
      const fieldErrors = base.fields || {} as Record<string, string[]>;

      const unmatchedFieldErrors: string[] = [];

      Object.keys(fieldErrors).forEach( (alias) => {
        const field = hasField(alias);
        fieldErrors[alias].forEach( (error: string) => {
          if (field) {
            addFieldError(alias, error);
          } else {
            unmatchedFieldErrors.push(error);
          }
        });
      });

      if (unmatchedFieldErrors.length > 0) {
        addError(unmatchedFieldErrors.join(', '));
      }

      return true;
    }

    const errorCode = axiosError?.response?.data?.error?.code;
    let errorMessage =
    axiosError?.response?.data?.error?.data?.reason ||
      axiosError?.response?.data?.error?.description;

    if (errorCode) {
      if (te(`errors.${errorCode}`)) {
        errorMessage = t(`errors.${errorCode}`);
      }
    }

    if (errorMessage) {
      addError(errorMessage);
      return true;
    }

    return false;
  };

  const hasField = (alias: string): FormField | undefined => {
    const field = formProps.modelValue.find(f => f.alias === alias);
    return field;
  };

  const getField = (alias: string): FormField => {
    const field = formProps.modelValue.find(f => f.alias === alias);
    if (!field) throw new Error(`field ${alias} not found`);
    return field;
  };

  const addFieldError = (alias: string, error: string) => {
    getField(alias);
    const fieldErrors = formProps.fieldErrors;
    if (! fieldErrors?.[alias] ) {
      fieldErrors[alias] = [];
    }
    fieldErrors[alias] = [ ...formProps.fieldErrors[alias], error ];
  };

  const setValuesFromProfile = (profileFields: Readonly<AccountVM['profile']>) => {
    profileFields.forEach( (pf) =>  {
      const field = hasField(pf.alias);
      if (field)
        switch (field.fieldType) {
        case 'date':
          field.value = [new Date(pf.value.raw as unknown as string)];
          break;
        case 'bool': {
          const val = pf.value.raw as unknown;

          if (typeof val === 'string') {
            field.value = val === 'true';
          } else if (val === null || val === undefined) {
            field.value = false;
          } else if (typeof val === 'boolean') {
            field.value = val;
          } else {
            throw new Error(`Unexpected value for ${pf.alias}: ${val}`);
          }

          break;
        }

        default:
          field.value = pf.value.raw;
        }
    });
  };

  const setLoading = (value: boolean) => formProps.loading = value;
  const setIsSent = (value: boolean) => formProps.isSent = value;
  const addError = (error: string) => formProps.errorMessages.push(error);

  const formMethods = {
    setFields,
    setLoading,
    hasField,
    getField,
    addFieldError,
    addError,
    hasErrors,
    cleanFieldErrors,
    clearErrors,
    parseApiValidation,
    setValuesFromProfile,
    reset,
    setIsSent,
    submit,
    validate,
  };

  return {
    props: formProps,
    methods: formMethods,
   } as const;
};
