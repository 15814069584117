<template>
  <div>
    <LinkBox v-bind="linkboxProps" />
    <VCheckbox
      v-model="checked"
      class="mt-4"
      variant="underline"
      :label="agreeLabel || t('uiSignupRegistration.agreement_label')"
      :rules="[v => !!v || agreeValidationMessage || t('uiSignupRegistration.agreement_required')]"
    />
  </div>
</template>

<script setup lang="ts">
import { useLocalization } from '@/composables';
import { DynamicStepComponentEvents, DynamicStepComponentProperties } from '@/models/dynamic-stepper-interface';
import { createObjectValidator } from '@/utils/eval-validator.ts';
import { computed, ref, watch } from 'vue';
import { FileEntry, type LinkBoxProps } from '../LinkBox.ts';

import LinkBox from '../LinkBox.vue';

const emit = defineEmits<{
} & DynamicStepComponentEvents<boolean>>();

const props = defineProps<{
  linkbox: LinkBoxProps,
  agreeLabel?: string,
  agreeValidationMessage?: string,
  formFieldsGetter?: () => Record<string, any>,
} & DynamicStepComponentProperties>();

const { t } = useLocalization('uiSignupRegistration');
const checked = ref(false);

const conditionFilteredFiles = ref<FileEntry[]>([]);
const linkboxProps = computed(() => ({
  ...props.linkbox,
  files: conditionFilteredFiles.value,
}));


function onEnter() {
  if (!props.formFieldsGetter)
    return;

  const fields = props.formFieldsGetter();
  if (! fields)
    throw new Error('FormFieldsGetter is not defined');

  const evalCheck = createObjectValidator(fields);

  conditionFilteredFiles.value = props.linkbox.files.filter( file => {
    if (! file.condition)
      return true;

    return evalCheck(file.condition);
  });

}

watch ( () => checked.value, (value) => {
  emit('stepNextDisabled', props.step!, !value);
}, { immediate: true });

watch( () => props.stepRequest, async (request) => {

  if (request?.event === 'on-enter') {
    onEnter();
  }

  if (request?.event === 'go-next') {
    emit('stepResponse', { step: props.step!, event: 'go-next', allow: checked.value });
  }

}, { immediate: true });

</script>
