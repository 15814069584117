

<template>
  <GravManagerWrapper :id="id" :componentName="componentName" :properties="props">
    <component :is="CountdownTimer" v-bind="{ style: $attrs.style, ...changableProps }">
      <template v-for="slotName in Object.keys($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </component>
  </GravManagerWrapper>
</template>

<script setup lang="ts">
import { CountdownTimerProps } from './CountdownTimer.ts';
import GravManagerWrapper from './GravManagerWrapper.vue';

import { useChangableProps } from '@/composables/useChangableProps';
import CountdownTimer from './CountdownTimer.vue';

const componentName = 'ui-countdown-timer';
const $slots = defineSlots<Record<string, unknown>>();

const props = defineProps<CountdownTimerProps>();
const changableProps = useChangableProps(componentName, props);

</script>
