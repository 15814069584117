<template>
  <div v-if="!errorText">
    <component
      :is="currentComponent"
      v-if="currentComponent"
      :items="parsedFeed"
      :linkboxProps="resultLinkboxProps"
    />
  </div>
  <v-alert
    v-else
    color="error"
  >
    {{ errorText }}
  </v-alert>
</template>

<script lang="ts" setup>
import { computed, markRaw, onMounted, ref } from 'vue';
import { JsonFeedProps, ParsedFeedItem, defaults } from './JsonFeed';

import { useLocalization } from '@/composables';
import SparkMD5 from 'spark-md5';

import type Feed from '@json-feed-types/1_1';
import DOMPurify from 'dompurify';

import BlogList from './JsonFeed/Blog/ItemsList.vue';
import EventList from './JsonFeed/Event/ItemsList.vue';
import FancyList from './JsonFeed/Fancy/ItemsList.vue';
import ResourceList from './JsonFeed/Resource/ItemsList.vue';

const props = withDefaults(defineProps<JsonFeedProps>(), {
  mode: () => defaults.mode,
  linkboxProps: undefined,
  buttonUrlText: undefined,
  buttonExternalUrlText: undefined,
});

const mode2component: Record<Exclude<JsonFeedProps['mode'], undefined>, any> = {
  fancy: markRaw(FancyList),
  events: markRaw(EventList),
  blog: markRaw(BlogList),
  resources: markRaw(ResourceList),
  videos: markRaw(ResourceList),
  academy: markRaw(ResourceList),
};

const resultLinkboxProps = computed(() => {
  if (props.linkboxProps) return props.linkboxProps;

  const defaults = {
    // backgroundClass: 'bg-secondary',
    // fileBackgroundClass: 'bg-primary elevation-2',
    // diesableWrapper: true,
    headerClass: 'text-h5',
    backgroundClass: 'bg-none elevation-0',
    fileIcon: 'mdi-file-document-outline',
    fileClass: 'v-col-md-6',
  };

  if (props.mode === 'videos') {
    defaults.fileIcon = 'mdi-youtube';
  }

  return defaults;
});

const currentComponent = computed(() => mode2component[props.mode]);

/*
В зависимости от фида используются те или иные поля для каждого поста:
Title - название
Image - картинка
Summary - краткий текст - используется для ленты, ивентов и академии
Content_html - полный текст - используется для ленты, ивентов и академии

Date_published - дата публикации, используется для лента и ивентов
Url - ссылка на данный пост на каком-то сайте (используется для кнопки share если применимо)
External_url - УРЛ внешнего ресурса: УРЛ видео с ютуба (для академии и видео-фида), УРЛ внешней PDFки (для Ресурсов) или УРЛ странички покупки билета (для календаря событий)
Tags - используются для структурирования фида по категориям (каждый тег это категория)


******* mode = events (Feed / Лента событий)
Лента событий это фактически записи блога.

Лента выводится с сортировкой по дате по убыванию (новое вверху). По каждой записи:

Левая колонка:
  Картинка

Правая колонка:

  Дата публикации
  Название
  Краткий текст

  Кнопка "Читать далее" (если есть ссылка на пост)


Клик на запись открывает отдельный экранчик с полными содержанием поста блога:

Одна колонка:
  Дата публикации
  Название
  Картинка
  Полный текст
  Кнопка Share/Поделиться которая видна только если есть URL


******* mode = blog, Events / События
Выводит посты блога из фида events.
Показаны ТОЛЬКО посты у которых дата ≥ сегодня.
Отсортированы по дате по возрастанию.

По каждому посту в списке:
Дата
Название
Картинка
Краткий текст

При клике открывается экранчик где:
Дата
Название
Картинка
Полный текст
Кнопка Share/Поделиться которая видна только если есть URL
Кнопка Buy ticket/Купить билет которая видна только если есть external_url и открывает этот УРЛ в браузере


******* Resources / Материалы
Выводит список материалов (презентаций) из соответствующего фида.

Материалы разделены по категориям (это tags из фида). По каждой категории выводится:
Название категории
Первые несколько постов в категории, по каждому посту:
Картинки
Название
Линк See all / Смотреть все , который показывает все посты выбранной категории

Клик на See all показывает только посты выбранной категории в виде сокращенной ленты,
в которой для каждого поста выводится только картинка и название, соответственно помещается
2 поста в одну строку:

При клике на пост (картинку либо название) открывается отдельный экранчик:
Названием
embedded PDF с данным материалом (например через https://pub.dev/packages/flutter_pdfview)
Кнопка Download/Скачать (сохранить себе на телефон)
Кнопка Share/Поделиться


**** Videos / Видео
Выводит список видео из соответствующего фида. Очень сильно похоже на ресурсы, отличается только сам пост.

Материалы разделены по категориям (это tags из фида). По каждой категории выводится:
Название категории
Первые несколько постов в категории, по каждому посту:
Картинки
Название
Линк See all / Смотреть все , который показывает все посты выбранной категории


Клик на See all показывает только посты выбранной категории в виде сокращенной ленты, в которой для каждого поста выводится только картинка и название, соответственно помещается 2 поста в одну строку.

Внешний вид этих двух экранов полностью аналогичен Resources.

При клике на пост (картинку либо название) открывается отдельный экранчик:
Название
Embedded youtube который берется из external_url
Кнопка Share которая позволяет отправить эту ссылку на видео.


**** Academy / Обучение
Выводит по категориям фид academy. Выглядит и работает аналогично блокам Resources и Videos.

Разница только в том, что обучение может быть как в видео, так и в текстовом формате. Поэтому при открытии уже конкретного поста в данном разделе открывается экранчик где выведено:
Название
Текст
embedded PDF с данным материалом  ИЛИ embedded Youtube видео, если ссылка (external_url) это youtube
Кнопок скачать/поделиться здесь НЕТ

*/

const { d } = useLocalization('uiJsonFeed');

const feed = ref<Feed | null>(null);
const errorText = ref<string | null>(null);
const parsedFeed = computed(() => feed.value?.items.map(feedItemParse) || []);

const sanitize = (html: string): string => DOMPurify.sanitize(html);
const removeHtmlTags = (html: string): string => html.replace(/<[^>]+>/g, '');

const level = ref<number>(0);


function hash(input: string) {
  return SparkMD5.hash(input); // Replace with your desired algorithm
}


function feedItemParse(item: Feed['items'][0]): ParsedFeedItem {

  const date = item.date_published ? d(new Date(item.date_published)) : '';
  const title = item.title || '';

  let imageUrlCutFromHtml = '';
  let html = item.content_html ? sanitize(item.content_html) : item.content_text || '';

  const imgTag = /<img[^>]+src="([^"]+)"[^>]*\/?>/.exec(html);
  if (imgTag) {
    imageUrlCutFromHtml = imgTag[1];
    html = html.replace(imgTag[0], '');
  }

  let image = item.image || imageUrlCutFromHtml;

  // https://web.telegram.org/a/blank.8dd283bceccca95a48d8.png

  if (image && /telegram.+blank/.test(image)) {
    image = '';
  }

  const id = item.id || hash(date.toString() + html);

  return {
    id,
    summary: item.summary || item.content_text || removeHtmlTags(html),

    tags: item.tags || [],

    date,
    title,
    image,
    html,

    url: item.url,
    externalUrl: item.external_url,

  };

}

// https://jsonplaceholder.typicode.com/posts
// https://www.reddit.com/r/pics.json

async function fetchFeed(url: string): Promise<Feed> {
  const response = await fetch(url);
  if (!response.ok) throw new Error(`Failed to fetch feed: ${response.statusText}`);
  return await response.json() as Feed;
}

onMounted(async() => {
  try {
    feed.value = await fetchFeed(props.url);
  } catch (error: any) {
    errorText.value = error?.message;
  }
});

</script>
