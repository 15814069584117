<template>
  <v-btn
    color="primary"
    @click="doShare(url, title || '', desc || '')"
  >
    {{ title || $t('uiJsonFeed.share') }}
  </v-btn>
</template>

<script setup lang="ts">
import { useToast } from '@/composables';
import { useI18n } from 'vue-i18n';

defineProps<{ url: string, title?: string, desc?: string }>();

const { t } = useI18n();

async function doShare(url: string, title: string, desc?: string): Promise<void> {
  if (navigator.share) {
    try {
      await navigator.share({
        title,
        text: desc,
        url,
      });
      console.log("Content shared successfully!");
    } catch (error) {
      console.error("Error sharing content:", error);
    }
  } else {
    try {
      await navigator.clipboard.writeText(url);
      useToast().ok(t('uiJsonFeed.shareNotSupported'));
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  }
}

</script>
