

export interface FileEntry {
  link: string;
  title: string;
  icon?: string;
  backgroundClass?: string;
  badge?: string;
  class?: string;
  condition?: string;
}

export interface LinkBoxProps {
  id?: string,
  files: FileEntry[],
  title?: string,
  fileIcon?: string,
  fileBackgroundClass?: string,
  fileClass?: string,
  backgroundClass?: string,
  headerClass?: string,
  rowHeight?: number,
  disableWrapper?: boolean,
}

export const defaults = {
  fileIcon: 'mdi-file-document-outline',
  fileBackgroundClass: 'bg-primary',
  fileClass: 'v-col-md-3',
};
