

<template>
  <GravManagerWrapper :id="id" :componentName="componentName" :properties="props">
    <component :is="JsonFeed" v-bind="{ style: $attrs.style, ...changableProps }">
      <!-- <template v-for="slotName in Object.keys($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template> -->
    </component>
  </GravManagerWrapper>
</template>

<script setup lang="ts">
import GravManagerWrapper from './GravManagerWrapper.vue';
import { JsonFeedProps } from './JsonFeed.ts';

import JsonFeed from './JsonFeed.vue';
import { useChangableProps } from '@/composables/useChangableProps';

const componentName = 'ui-json-feed';

const props = defineProps<JsonFeedProps>();
const changableProps = useChangableProps(componentName, props);

</script>
