import { gravComponentSchema } from '../deep-form-grav';
import { JsonFeedProps, defaults } from '../../../../components/ui/JsonFeed';

const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<JsonFeedProps>({
  title: 'JsonFeed',
  link: '',
  text: text(),
},
  defaults,
);

/*
export interface JsonFeedProps {
  id?: string;
  url: string;
  mode?: 'events' |  'blog' | 'academy' | 'resources' | 'videos';
}
*/

export default toExport;


s.add('url').input('vtext').title('URL').col(12).mandatory();
s.add('mode').input('dictionary').data([
  { title: 'Fancy', value: 'fancy' },
  { title: 'Events', value: 'events' },
  { title: 'Blog', value: 'blog' },
  { title: 'Academy', value: 'academy' },
  { title: 'Resources', value: 'resources' },
  { title: 'Videos', value: 'videos' },
]).title('Mode').col(12).mandatory();

function text() {
  return `
<b>JsonFeed</b>
Allows to display a feed of items from a JSON feed.
`;
}
