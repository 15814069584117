<template>
  <div>
    <MediaPreview :item="item" />
    <PostHeader :item="item" />
    <PostBody class="mt-2" :item="item" />
    <div class="mt-4 d-flex justify-center">
      <BtnBack @back="emit('back')" />
      <BtnShare v-if="item.url" class="ml-2" :url="item.url" />
      <BtnBuyTicket v-if="item.externalUrl" class="ml-2" :url="item.externalUrl" />
    </div>
  </div>
</template>


<script lang="ts" setup>
import { useDisplay } from 'vuetify';
import { ParsedFeedItem } from '../../JsonFeed';

import BtnBack from '../parts/BtnBack.vue';
import BtnBuyTicket from '../parts/BtnBuyTicket.vue';
import BtnShare from '../parts/BtnShare.vue';
import MediaPreview from '../parts/MediaPreview.vue';
import PostBody from '../parts/PostBody.vue';
import PostHeader from '../parts/PostHeader.vue';

defineProps<{ item: ParsedFeedItem }>();

const emit = defineEmits<{
  (event: 'back'): void;
}>();

const { xs } = useDisplay();
</script>
