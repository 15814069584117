import { ConfirmableFieldAlias } from "@/models/field-confirmation-interface";
import { InputByTypeProperties } from "@/models/form-builder-interface";
import { FileEntry, LinkBoxProps } from "./LinkBox";

export interface PropStep {
  title: string;
  aliases: string[];
}

export type ConfirmationMode = true | false | 'optional';

export interface SignupRegistrationProps {
  id?: string;
  steps?: PropStep[];
  inputProperties?: InputByTypeProperties;
  optionalFields?: string[];
  fieldTitles?: Record<string, string>;
  otpConfirm?: Record<ConfirmableFieldAlias, ConfirmationMode>;
  forceFields?: Record<string, string>;
  autoLogin?: boolean;
  altLabels?: boolean;

  agreementFiles?: FileEntry[];
  agreementTitle?: string;
  agreementValidationText?: string;
  agreementLinkboxProps?: Partial<LinkBoxProps>;
}

// export interface LinkBoxProps {
//   id?: string,
//   files: FileEntry[],
//   title?: string,
//   fileIcon?: string,
//   fileBackgroundClass?: string,
//   fileClass?: string,
//   backgroundClass?: string,
//   headerClass?: string,
//   rowHeight?: number,
//   disableWrapper?: boolean,
// }

export const defaults = {
  steps: [
    { title: 'uiSignupRegistration.account_information', aliases: ['sponsor_id', 'firstname', 'lastname'] },
    { title: 'uiSignupRegistration.contact_details', aliases: ['email', 'password', 'password_confirm'] },
  ],
  otpConfirm: {
    'email': true,
    'phone': 'optional' as const,
  },
  autoLogin: true,

  agreementLinkboxProps: {
    backgroundClass: 'bg-none elevation-0',
    disableWrapper: true,
    fileIcon: 'mdi-file-document-outline',
    fileClass: 'v-col-md-6',
  },

};
