<template>
  <div>
    <div v-if="!xs && !!item.image">
      <div class="v-row">
        <div class="v-col-md-6">
          <MediaPreview :item="item" />
        </div>
        <div class="v-col-md-6">
          <PostHeader :item="item" />
          <PostBody class="mt-2" :item="item" />
          <div class="mt-8 d-flex justify-center">
            <BtnBack @back="emit('back')" />
            <BtnShare v-if="item.url" class="ml-2" :url="item.url" />
            <BtnBuyTicket v-if="item.externalUrl" class="ml-2" :url="item.externalUrl" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <MediaPreview :item="item" />
      <PostHeader :item="item" />
      <PostBody class="mt-2" :item="item" />
      <div class="mt-4 d-flex justify-center">
        <BtnBack @back="emit('back')" />
        <BtnShare v-if="item.url" class="ml-2" :url="item.url" />
        <BtnBuyTicket v-if="item.externalUrl" class="ml-2" :url="item.externalUrl" />
      </div>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { useDisplay } from 'vuetify';
import { ParsedFeedItem } from '../../JsonFeed';

import BtnBack from '../parts/BtnBack.vue';
import BtnBuyTicket from '../parts/BtnBuyTicket.vue';
import BtnShare from '../parts/BtnShare.vue';
import MediaPreview from '../parts/MediaPreview.vue';
import PostBody from '../parts/PostBody.vue';
import PostHeader from '../parts/PostHeader.vue';

defineProps<{ item: ParsedFeedItem }>();

const emit = defineEmits<{
  (event: 'back'): void;
}>();

const { xs } = useDisplay();
</script>
