
import emptyAvatar from '@/assets/images/avatar_undefined.png';
import { ColorScheme } from '../conditional-colors';
import { presentableId, idWithOffset, fullName, namePlusId } from '../properties-calculated';
import { runTemplate } from '../property-template-service';

export type TableGridItemProperty = {
  raw: any,
  presentable: string | number | object | undefined | null,
}

export type TableGridItem = Record<string, TableGridItemProperty> & {
  rowColor?: ColorScheme;
  raw?: any;
}

export type TableGridColumn = {
  name: string;
  title: string;

  sortable?: boolean;

  noWrap?: boolean;
  hidden?: 'always' | 'by_default';

  noArraySpread?: boolean;

  fieldType?: string; // keyof Scalars, but allow flexibility
  filterMode?: 'outside' | 'inside' | 'none';

  viewComponent?: any;
  viewComponentProps?: Record<string, any>;

  filterComponent?: any; // override
  filterComponentProps?: Record<string, any>; // override

  align?: 'left' | 'center' | 'right';
  format?: (value: TableGridItemProperty['raw'], item?: TableGridItem) => string;
}

export interface DataTableServerOptions {
  page: number;
  itemsPerPage: number;
  sortBy: Array<{ key: string; order: 'asc' | 'desc' | '' }>;
  groupBy: Array<{ key: string; order: 'asc' | 'desc' | '' }>;
  search?: string;
  expanded?: string[];
  grouped?: string[];
}

export interface TableGridPaginationState {
  page: number;
  limit: number;
  orderBy?: Array<{
    key: string;
    order: 'asc' | 'desc' | '';
  }>;
}

export function mapPaginationToVuetify(pagination?: TableGridPaginationState): DataTableServerOptions | undefined {

  if (! pagination) return {
    page: 0,
    itemsPerPage: 10,
    sortBy: [],
    groupBy: [],
  };

  return {
    page: pagination.page,
    itemsPerPage: pagination.limit === Number.MAX_SAFE_INTEGER ? -1 : pagination.limit || 10,
    sortBy: pagination.orderBy || [],
    groupBy: [],
  };
}

export type PresentableValueFn = (prop: string) => string;
export type RawValueFn = (prop: string) => TableGridItemProperty['raw'];

 
export function slotParams(srcItem: TableGridItem, value?: any, additional: Record<string, TableGridItemProperty['raw']> = {}) {
  const item = Item(srcItem);
  return {
    item,
    p: item.p,
    t: item.t,
    r: item.r,
    n: item.n,
    value,
    ...additional,
  };
}

export function createTableGridRows<T extends Object>(modelName: string, data: Array<T>): TableGridItem[] {
  const rows: Array<TableGridItem> = [];

  data.forEach((row) => {
    const newRow: TableGridItem = {};

    for (const [key, value] of Object.entries(row)) {
      const fullName = `${modelName}.${key}`;
      newRow[fullName] = {
        raw: value,
        presentable: String(value),
      };
    }

    rows.push(newRow);
  });

  return rows;
}


// --- ui2 Position typing Item

export type ItemType = ReturnType<typeof Item>;

export function itemsEqual(item1?: TableGridItem, item2?: TableGridItem) {
  if (!item1 || !item2)
    return false;
  return (item1['s.id']?.raw === item2['s.id']?.raw)
    && (item1['vmdl.offset']?.raw === item2['vmdl.offset']?.raw);
}

// convenience item wraper
export function Item(row: TableGridItem) {

  function accountId() {
    return Number(row['s.id']?.raw);
  }

  function positionId() {
    return Number(row['vmdl.id']?.raw || 0);
  }

  function kids() {
    return Number(row['vmdl.childrenCount']?.raw);
  }

  function level() {
    // when we fetch whole downline, we rely on vmdl.level from api
    // when we gradually fetch branches using useGealogy, we rely on calculated t.level
    const lvl =
      row['t.level']?.raw !== undefined ? Number(row['t.level']?.raw) : Number(row['vmdl.level']?.raw);

    return lvl;
  }

  function offset() {
    return Number(row['vmdl.offset']?.raw || 1);
  }

  function treeId() {
    return Number(row['vmdl.treeId']?.raw);
  }

  function parentPositionId() {
    return Number(row['vmdl.parentPositionId']?.raw);
  }

  function parentAccountId() {
    return Number(row['parent.id']?.raw);
  }

  function number() {
    return Number(row['vmdl.number']?.raw);
  }

  function avatarUrl() {
    if (typeof row['p.avatar_id']?.raw !== 'object' || !row['p.avatar_id']?.raw)
      return emptyAvatar;

    const avatarProp: { thumbUrl?: string } = row['p.avatar_id'].raw;
    return avatarProp.thumbUrl ?? emptyAvatar;
  }

  function email() {
    return String(row['p.email']?.raw || '');
  }

  function sameAs(item: ItemType) {
    if ( accountId() === item.accountId() && offset() === item.offset() )
      return true;
    return false;
  }

  const p = (prop: string) => row ? String(row[ prop ]?.presentable || '')  : '';
  const r = (prop: string) => row ? row[ prop ]?.raw : undefined;
  const t = (template: string) => row ? runTemplate(template, row) : '';
  const n = (prop: string) => Number(row[ prop ]?.raw || 0);

  return {
    accountId,

    presentableId: () => presentableId(row),
    idWithOffset: () => idWithOffset(row),
    fullName: () => fullName(row),
    namePlusId: () => namePlusId(row),

    avatarUrl,
    email,
    positionId,
    kids,
    level,
    offset,
    number,
    parentPositionId,
    parentAccountId,
    treeId,

    sameAs,
    getRaw: () => row,
    p, t, r, n,

  };
}
