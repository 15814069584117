<!-- eslint-disable vue/no-unused-properties -->
<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup lang="ts">

import {computed, nextTick, onMounted, onUnmounted, ref, watch} from 'vue';

import { useAwaitAccountId, useLocalization, useNavigation, useProperties, useTree } from '@/composables';
import { DGroup, useTreeRender, type RenderCore } from '@/composables/useTreeRender';

import { InputByTypeProperties } from '@/models/form-builder-interface';
import { Item, TableGridItem } from '@/models/table-grid-interface';
import { TreeFetchResult, TreeNode, createNodeId, parseNodeId, validNodeId } from '@/models/tree-interface';
import { CardLayout, RenderFunction, TreeRenderComponentMethods } from '@/models/tree-render-component-interface';
import { AccountSearchItem, accountSearchItemFromItem, fetchAccountSearchInfo } from '@/utils/account-search-view';

import { ColorRules } from '@/utils/conditional-colors';

import { apiErrorToString } from '@ui-api3-sdk/api/api3';
import { useThemeStore } from '@/pinia/useThemeStore';
import { fetchPositions } from '@/utils/fetch-positions-service';

import { useAccountStore } from '@/pinia/useAccountStore';
import { useCacheStore } from '@/pinia/useCacheStore';
import { untilFalse } from '@/utils/reactive-helpers';
import { formatAccountId } from '@/utils/utils';

import { UseTreeOptions } from '@/composables/useTree';
import { VBtn } from 'vuetify/lib/components/index.mjs';

import AccountSearch from '@/components/ui/base/AccountSearch.vue';

const props = withDefaults(defineProps<{
  properties?: string[],
  cardCreate: RenderFunction,
  cardUpdate: RenderFunction,
  cardLayout: CardLayout,

  accountId?: number;
  treeId?: number;
  initialFetch?: number,
  uplineLimit?: number,
  headerTitles?: Record<string, string>;
  colors?: ColorRules;
  backgroundColor?: string;
  inputProperties?: InputByTypeProperties;
  accountSearchPrependItems?: AccountSearchItem[];
  useTreeOptions?: UseTreeOptions,
}>(), {
  properties: () => [],
  headerTitles: undefined,
  accountId: undefined,
  treeId: 0,
  colors: undefined,
  inputProperties: undefined,
  useTreeOptions: undefined,
  backgroundColor: 'rgb(196, 201, 250, 0.5)',
  initialFetch: 2,
  uplineLimit: 500,
  accountSearchPrependItems: () => [],
});

const emit = defineEmits<{
  (event: 'treeFetched', result: TreeFetchResult): void,
  (event: 'initialized', result: TreeRenderComponentMethods): void,
}>();

const { lt, isLoading: i18nLoading } = useLocalization('treeRender');

const mandatoryFields = [
  's.id',
  'r.id',
  'p.avatar_id',
  'p.firstname',
  'p.lastname',
  'p.email',

  'vmdl.id',
  'vmdl.number',
  'vmdl.childrenCount',
  'vmdl.offset',
  'vmdl.level',

  'vmdl.parentPositionId',
  'parent.id',
];

(props.colors?.fields || []).forEach(f => {
  if (f[0] ?? !mandatoryFields.includes(f[0])) mandatoryFields.push(f[0]);
});

const usedProperties = useProperties(
  props.properties || [],
  mandatoryFields,
  props.headerTitles,
);

const tree = useTree(
  props.treeId,
  usedProperties.propsWithMandatory.value,
  [],
  props.colors,
  props.uplineLimit,
  props.useTreeOptions,
);

const render = useTreeRender({
  ...props.cardLayout,
  cardCreate: cardCreateForRender,
  cardUpdate: cardUpdateForRender,
});

// const lastRequestHitLimit = computed(() => tree.lastRequestHitLimit.value);

const selectedAccount = ref<AccountSearchItem | undefined>();
const selectedAccountOtherPositions = ref<number[]>([]);

const resultingAccountId = ref<number | undefined>(props.accountId);
const resultingAccountOffset = ref<number>(1);

const currentItem = ref<ReturnType<typeof Item> | undefined>();

const chartWidth = ref(0);
const chartHeight = ref(0);

const loading = ref(false);
const root = ref<TreeNode | undefined>(undefined); // draw root (may be upline)
const realRoot = ref<TreeNode | undefined>(undefined);  // real node corresponding to current selected account

const svgRef = ref<SVGElement | undefined>();
const containerRef = ref<HTMLDivElement | undefined>();

const fullscreen = ref(false);
const errorMsg = ref('');
const errorOpen = ref(false);

watch ( selectedAccount, async () => {
  updateResultingAccount();
}, { deep: true });

watch( [loading], () => {

  const renderCore = render.getCore();
  if (! renderCore ) return;

  if (loading.value) { // to display rotating progress circle on node
    const currentRoot = tree.getUplineRoot();
    if (! currentRoot) return;
    renderCore.render(tree.cloneWithoutClosedKids(currentRoot));
    return;
  }

  if (! root.value ) return;

  renderCore.render(root.value);

});


watch( [resultingAccountId, resultingAccountOffset], async () => {
  const renderCore = render.getCore();
  if (! resultingAccountId.value || ! renderCore) return;
  fetchOtherPositions();
  tree.resetTree();
  await fetchData(resultingAccountId.value, resultingAccountOffset.value, props.initialFetch);
  if (root.value) {
    renderCore.render(root.value);
    zoomCenter();
  }
});

// todo: this is ugly. There should be a way to deal with screen size without event listeners even.
watch( [ () => useThemeStore().drawer ], () => {
  setTimeout(onScreenResize, 300);
});

async function toggleKids(id: string, depth = 1) {
  const renderCore = render.getCore();
  if (! resultingAccountId.value || ! renderCore ) return;
  tree.toggleOpen(id);
  await fetchData(resultingAccountId.value, resultingAccountOffset.value, depth);
}

async function updateResultingAccount() {
  if (! selectedAccount.value ) {
    const myAccountId = await useAwaitAccountId(props.accountId);
    selectedAccount.value = {
      accountId: myAccountId,
      formatAccountId: formatAccountId(myAccountId, useAccountStore().idMask),
      fullname: lt('my_account'),
      email: '',
      offset: 1,
    };
  }
  resultingAccountId.value = selectedAccount.value.accountId;
  resultingAccountOffset.value = selectedAccount.value.offset || 1;
}

async function fetchOtherPositions() {
  if (! resultingAccountId.value ) return;
  const positions = await fetchPositions(resultingAccountId.value, props.treeId, ['vmdl.offset']);
  selectedAccountOtherPositions.value =
    positions.map(p => Item(p).offset()).sort( (a, b) => Number(a) - Number(b));
}

function setAnotherPosition(offset: number) {
  if (! selectedAccount.value ) return;
  selectedAccount.value = { ...selectedAccount.value, offset };
}

async function setCurrentAccountByNodeId(nodeId?: string, item?: TableGridItem) {

  if (! nodeId ) {
    selectedAccount.value = undefined;
    return;
  }

  if (! validNodeId(nodeId) ) {
    console.error('setCurrentAccountByNodeId: invalid nodeId', nodeId);
    return;
  }

  const nid = parseNodeId(nodeId);

  if (nid.accountId === resultingAccountId.value) {
    if (nid.offset === resultingAccountOffset.value) return toggleKids(nodeId, 1);
  }

  const rItem = item ?? tree.findItemByNodeId(nodeId);

  if (! rItem ) {

    const res = await fetchAccountSearchInfo(nid.accountId);

    for (const item of res) {
      if (item.accountId === nid.accountId) {
        selectedAccount.value = { ...item, offset: nid.offset };
        return;
      }
    }

  }

  const searchItem = accountSearchItemFromItem(rItem || {});
  searchItem.offset = nid.offset;
  searchItem.accountId = nid.accountId;
  selectedAccount.value = searchItem;

}

async function loadMore() {
  // console.log('loadMore', lastFecthParams);
  try {
    loading.value = true;

    await tree.continueFetch();

    const rootNode = tree.getUplineRoot();
    const uplineRootNode = tree.getUplineRoot();
    if (! rootNode || ! uplineRootNode ) return;

    root.value = tree.cloneWithoutClosedKids(rootNode);

    render.getCore()?.render(root.value!);
    zoomCenter();

    loading.value = false;

  } catch (e) {
    loading.value = false;
    console.error(e);
    errorMsg.value = apiErrorToString(e, 'Error fetching tree');
    errorOpen.value = true;
  }
}

async function fetchData(accountId: number, offset = 1, depth = 1) {


  try {
    loading.value = true;

    const result = await tree.fetchTree(accountId, depth, offset);

    const wasNewFetch = !root.value;

    root.value = tree.cloneWithoutClosedKids(result.uplineRoot);
    realRoot.value = result.root;

    loading.value = false;

    const rItem = tree.findItemByNodeId( createNodeId(accountId, offset) );
    currentItem.value = rItem ? Item(rItem) : undefined;
    if (rItem) {
      useCacheStore().updateCache(rItem, accountId);
    }
    emit('treeFetched', result);

    return wasNewFetch;

  } catch (e) {
    loading.value = false;
    console.error(e);
    errorMsg.value = apiErrorToString(e, 'Error fetching tree');
    errorOpen.value = true;
    return false;
  }
}

function zoomCenter() {
  const renderCore = render.getCore();
  if (! renderCore || ! realRoot.value ) return;
  renderCore.resetZoom(realRoot.value.id, -1);
}

async function fetchAndRender(accountId: number, offset = 1, depth = 1) {
  const wasNewFatch = await fetchData(accountId, offset, depth);
  render.getCore()?.render(root.value!);
  if (wasNewFatch) zoomCenter();
}

function cardCreateForRender(
  nodes: DGroup,
  cardContents: DGroup,
  emptyCardContents: DGroup,
  core: RenderCore,
) {
  return props.cardCreate({
    nodes,
    emptyCardContents,
    cardContents,
    core,
    propertyTypes: usedProperties.propertyTypes,
    methods: {
      toggleKids,
      setCurrentAccountByNodeId,
      fetchAndRender,
      resetTree: () => tree.resetTree(),
      resetZoom: core.resetZoom,
      loadMore,
    },
  });

}

function cardUpdateForRender(
  nodes: DGroup,
  cardContents: DGroup,
  emptyCardContents: DGroup,
  core: RenderCore,
) {

  return props.cardUpdate({
    nodes,
    emptyCardContents,
    cardContents,
    core,
    propertyTypes: usedProperties.propertyTypes,
    methods: {
      toggleKids,
      setCurrentAccountByNodeId,
      fetchAndRender,
      resetTree: () => tree.resetTree(),
      resetZoom: core.resetZoom,
      loadMore,
    },
  });

}


const toggleFullscreen = () => {
  if (! containerRef.value ) return;
  if (!fullscreen.value) {
    containerRef.value.requestFullscreen();
  } else {
    containerRef.value.ownerDocument.exitFullscreen();
  }

  fullscreen.value = !fullscreen.value;
  nextTick(onScreenResize);
};

function updateChartDimensions() {
  if (! containerRef.value) return;

  chartWidth.value = containerRef.value.clientWidth;
  chartHeight.value = containerRef.value.clientHeight;
}

function onScreenResize() {
  updateChartDimensions();
  // console.log('onScreenResize', chartWidth.value, chartHeight.value);
  render.getCore()?.updateScreenSize(chartWidth.value, chartHeight.value);
}

onMounted(async () => {
  console.time('Waiting for i18n');
  await untilFalse(i18nLoading);
  console.timeEnd('Waiting for i18n');
  await updateResultingAccount();

  if (! svgRef.value  )
    return console.error('SVG element not ready');

  if (! resultingAccountId.value )
    return console.error('Account not ready');

  const setNodeIdFromParams = useNavigation().param('nodeId');
  if (setNodeIdFromParams) {
    await setCurrentAccountByNodeId(setNodeIdFromParams);
  }

  await fetchData(resultingAccountId.value, resultingAccountOffset.value, props.initialFetch);

  updateChartDimensions();

  render.init(chartWidth.value, chartHeight.value, svgRef.value);
  const renderCore = render.getCore();

  if (! root.value || !renderCore ) {
    console.error('Tree was not fetched or render core not ready.');
    return;
  }

  renderCore.render(root.value);
  zoomCenter();

  window.addEventListener('resize', onScreenResize);

  emit('initialized', {
    toggleKids,
    setCurrentAccountByNodeId,
    fetchAndRender,
    resetTree: () => tree.resetTree(),
    resetZoom: renderCore.resetZoom,
    loadMore,
  });

  fetchOtherPositions();

});
const showDisabledAccounts = computed(()=> props.useTreeOptions?.showInactive === 'only_disabled' || props.useTreeOptions?.showInactive === 'all_inactive')

onUnmounted(() => {
  window.removeEventListener('resize', onScreenResize);
});


</script>

<template>
  <div>
    <slot
      name="above"
      :fullscreen="fullscreen"
      :item="currentItem"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"
    />

    <div
      ref="containerRef"
      class="container bg-background"
    >
      <div
        class="container-background-overlay"
        :style="`background-color: ${props.backgroundColor}`"
      >
        <svg
          ref="svgRef"
          class="svg"
        >
          <!-- Volar goes mad -->
        </svg>
        <div
          v-if="tree.lastRequestHitLimit.value"
          class="bottom-alert"
        >
          <v-tooltip location="top">
            {{ lt('limit_hit') }}
            <template #activator="{ props }">
              <div
                class="bg-error text-white p-2 rounded"
                v-bind="props"
              >
                <VBtn
                  :disabled="loading"
                  variant="text"
                  @click="loadMore"
                >
                  {{ lt('load_more') }}
                  <!-- <v-icon>mdi-chevron-right</v-icon>... -->
                </VBtn>
              </div>
            </template>
          </v-tooltip>
        </div>

        <div class="toolbar toolbar-account">
          <div class="d-flex flex-row">
            <div>
              <AccountSearch
                v-model="selectedAccount"
                :forceLoading="tree.loading.value"
                class="account-search"
                :inputProperties="inputProperties"
                :prependItems="accountSearchPrependItems"
                :attachTo="containerRef"
                :hideDetails="true"
                :networkSearch="false"
                :showDisabledAccounts="showDisabledAccounts"
              />

              <div class="d-flex flex-column flex-sm-row">
                <div v-if="selectedAccountOtherPositions.length > 1">
                  <v-menu v-if="selectedAccountOtherPositions.length > 3">
                    <template #activator="{ props }">
                      <!-- color="rgba(128, 128, 128, 0.7)" -->
                      <VBtn
                        v-bind="props"
                        size="x-small"
                        color="rgba(128,128,128, 1)"
                        variant="plain"
                        appendIcon="mdi-menu-down"
                      >
                        {{ lt('position', [resultingAccountOffset]) }}
                      </VBtn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="offset in selectedAccountOtherPositions"
                        :key="offset"
                        @click="setAnotherPosition(offset)"
                      >
                        <v-list-item-title>{{ offset }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <VBtn
                    v-for="offset in selectedAccountOtherPositions"
                    v-else
                    :key="offset"
                    class="mr-1"
                    size="x-small"
                    color="rgba(128, 128, 128, 0.8)"
                    variant="outlined"
                    :disabled="offset === resultingAccountOffset"
                    @click="setAnotherPosition(offset)"
                  >
                    {{ offset }}
                  </VBtn>
                </div>

                <div>
                  <slot name="belowAccountSearch" :selectedAccount="selectedAccount" />
                </div>
              </div>
            </div>
            <div>
              <slot name="rightOfAccountSearch" />
            </div>
          </div>
        </div>
        <div class="toolbar toolbar-fullscreen">
          <div class="d-flex flex-row">
            <div>
              <slot name="leftOfFullscreen" />
            </div>

            <VBtn class="half-transparent" icon @click="toggleFullscreen">
              <v-progress-circular
                v-if="tree.loading.value"
                :data-loading="true"
                indeterminate
                color="primary"
                :size="40"
              />
              <v-icon v-else>{{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
            </VBtn>
          </div>
        </div>

        <v-dialog
          v-model="errorOpen"
          width="auto"
          :attach="containerRef"
        >
          <v-alert
            type="error"
            closable
            @click:close="errorOpen = false"
          >
            {{ errorMsg }}
          </v-alert>
        </v-dialog>

        <slot :fullscreen="fullscreen" :containerRef="containerRef" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">


.container {
  position: relative;
  height: calc(100vh - 160px);
  // in fullscreen height is 100vh

  &-background-overlay {
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;

    border-radius: 10px;
  }
}


.svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100%;

  border-radius: 10px;
  // border: 1px dashed rgb(0, 0, 0);
}

.toolbar {
  position: absolute;
  z-index: 1000;
  top: 20px;
  padding: 0px;

  &-account {
    left: 20px;
  }

  &-fullscreen {
    right: 20px;

    @media (max-width: 520px) {
      display: none;
    }
  }

}

.bottom-alert {
  position: absolute;
  z-index: 1000;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  opacity: 0.8;
}

.account-search {
  width: 400px;

  @media (max-width: 520px) {
    width: calc(100vw - 60px);
  }
}

.half-transparent {
  opacity: 0.4;
}

</style>


<style global>
@keyframes rotate {

  100% {
        transform: rotate(1turn);
    }
}
</style>
@/pinia/useCacheStoreStore
