import { ref } from "vue";

import { Item, TableGridItem } from "@/models/table-grid-interface";
import { FilterItem, useTreeApi } from '@ui-api3-sdk/api/api3';

import { TreeFetchResult, TreeNode, createNodeFromItem, createNodeId, nodeIdFromItem, parseNodeId, sameNode, sameNodeTP, tpFromItem } from "@/models/tree-interface";

import { DOWNLINE_MAX_LIMIT, DownlineServiceParams, fetchDownline, removeDuplicateNodes } from '../utils/fetch-downline-service';
import { fetchFrontline, FrontlineServiceParams } from "@/utils/fetch-frontline-service";
import { fetchUpline } from "@/utils/fetch-upline-service";
import { fetchMyPosition } from '@/utils/fetch-my-position-service';

import { useAwaitAccountId } from './useAwaitAccountId';
import { ColorRules, getColorScheme } from '@/utils/conditional-colors';

export type UseTreeOptions = {
  showInactive?: DownlineServiceParams['showInactive'],
  maxNodesPerRequest?: number,
  placementMode?: boolean;
};

async function getTreeInfo(id: number) {
  const result = await useTreeApi().getTrees();
  const tree = result.data.payload.find((t) => t.id === id);
  if (!tree) throw new Error('Tree not found');
  return tree;
}

export function useTree(
  treeId: number,
  properties: string[],
  currentFilter: FilterItem[] = [],
  colorRules?: ColorRules,
  uplineLimit = 0,
  options?: UseTreeOptions,
) {

  const nodes = ref<Record<string, TreeNode>>({});
  const rootNode = ref<TreeNode>();
  const tableItems = ref<TableGridItem[]>([]);

  const uplineRootNode = ref<TreeNode>();
  const uplineStartLevel = ref(0);

  const loading = ref(false);

  const lastRequestHitLimit = ref< false | 'frontline' | 'downline'>(false);

  const lastFetchDLParams = ref<{
    maxNodes: number,
    fetchedNodes: number,
    dp: DownlineServiceParams
   } | undefined>();

  const lastFetchFLParams = ref<FrontlineServiceParams | undefined>();

  let matrixBase: number | undefined = undefined;

  function findItemByNodeId(nodeId: string): TableGridItem | undefined {
    if (!nodeId) return undefined;
    const tp = parseNodeId(nodeId);
    return tableItems.value.find((item) => {
      const tp2 = tpFromItem(item);
      if (!tp2) {
        console.error('BAD TREE POSITION', item);
        return false;
      }
      return sameNodeTP(tp2, tp);
    });
  }

  function enrichItem(item: TableGridItem, level: number, colorRules?: ColorRules) {

    const newItem = { ...item };
    newItem['t.level'] = { raw: level, presentable: level.toString() };

    if (colorRules) {
      newItem.rowColor = getColorScheme(newItem, colorRules);
    }

    return newItem;
  }

  function normalizeChildren(parentNodeId: string, children: TreeNode[]) {

    const pMode = options?.placementMode === true;

    if ( (!matrixBase || children.length >= matrixBase) && !pMode )
      return children;

    const kidExists: Record<number, TreeNode> = {};

    children.forEach((child) => {
      kidExists[child.number] = child;
    });

    const resMaxKids = pMode ? children.length + 1 : matrixBase!;

    const newChildren = [];

    for (let i = 1; i <= resMaxKids; i++) {
      if (kidExists[i]) {
        newChildren.push(kidExists[i]);
        continue;
      }
      newChildren.push({
        item: {},
        id: `empty/${parentNodeId}/${i}`,
        childrenCount: 0,
        open: false,
        wasOpen: false,
        isEmpty: true,
        number: i,
        children: [] as TreeNode[],
        loading: false,
      } as TreeNode);
    }

    return newChildren;
  }

  async function unwrap(nodes: TreeNode[], level: number, depth = 1): Promise<TableGridItem[]> {
    const result: TableGridItem[] = [];
    for (const node of nodes) {
      const tableItem = enrichItem(node.item, level, colorRules);
      result.push(tableItem);
      node.item = tableItem;
      if (node.isEmpty) continue;

      if (node.open && node.childrenCount > 0) {
        if (node.children.length === 0) {
          node.children = normalizeChildren(node.id, depth === 1 ?
            await fetchViaFrontline(node.id) :
            await fetchViaDownline({ nodeId: node.id, depth, onlyKids: true }),
          );
        }
        result.push(...await unwrap(node.children, level + 1, depth));
      } else if (matrixBase || options?.placementMode) {

        if (node.childrenCount === 0) {
          node.children = normalizeChildren(node.id, []);
          node.childrenCount = matrixBase || 1;
          node.open = true;
          node.wasOpen = true;
        }

      }
    }
    return result;
  }


  async function fetchUplineRootNode(accountId: number, uplineLimit: number, offset: number) {

    function createUplineNode(item: TableGridItem, level: number) {
      const newItem = { ...item };
      newItem['vmdl.childrenCount'] = { raw: 1, presentable: 1 };
      const node = createNodeFromItem(enrichItem(newItem, level, colorRules));
      node.wasOpen = true;
      node.open = true;
      nodes.value[node.id] = node;
      return node;
    }

    try {
      const result = await fetchUpline({
        accountId,
        treeId,
        offset,
        pagination: {
          page: 0,
          limit: uplineLimit,
        },
        properties,
      });

      if (result.totalItems <= 1) {
        return rootNode.value;
      }

      const items = [...result.tableItems];
      items.pop();


      uplineStartLevel.value = -1 * items.length;
      let level = uplineStartLevel.value;

      const uplineRootNode = createUplineNode(items[0], level);
      let currentNode = uplineRootNode;

      items.shift(); // remove self from upline reply

      for (const item of items) {
        level++;
        const node = createUplineNode(item, level);
        currentNode.children.push(node);
        currentNode = node;
      }

      if (rootNode.value) {
        currentNode.children.push(rootNode.value);
      }

      return uplineRootNode;

    } catch (e) {
      console.error(e);
      return rootNode.value;
    }
  }

  async function fetchRootPosition(accoundId: number, depth = 1, offset = 1) {

    if (matrixBase === undefined)
      matrixBase = (await getTreeInfo(treeId)).matrix_base || 0;

    const nodeId = createNodeId(accoundId, offset);

    const kids = await fetchViaDownline({ nodeId, depth: Math.max(0, depth), onlyKids: false });
    const totalItems = kids.length;

    const selfInDownline = kids.find((kid) => sameNode(kid.id, nodeId));

    if (selfInDownline) {
      return selfInDownline;
    }

    const currentId = await useAwaitAccountId();
    if (currentId !== parseNodeId(nodeId).accountId) {
      throw new Error('No way to fetch position. Not in downline and not current account.');
    }

    const pos =
      await fetchMyPosition(treeId, properties);

    if (!pos) {
      throw new Error('No position in the tree.');
    }

    const item = pos?.item;

    item['vmdl.childrenCount'] = { raw: totalItems, presentable: totalItems };

    const node = createNodeFromItem(item);
    node.children = normalizeChildren(node.id, kids);
    nodes.value[node.id] = node;

    return node;
  }


  async function fetchViaDownline(params: {
    nodeId: string,
    depth: number,
    onlyKids?: boolean,
  }) {

    const { nodeId, depth, onlyKids } = params;

    setNodeLoading(nodeId, true);

    const returnNodes: TreeNode[] = [];
    const returnPositionIds = new Set<number>();

    const nid = parseNodeId(nodeId);
    const dlParams: DownlineServiceParams = {
        treeId,
        positionId: nid.positionId ? nid.positionId : undefined,
        offset: nid.positionId ? undefined : nid.offset ?? 1,
        accountId: nid.accountId,
        properties,
        filter: currentFilter,
        depth,
        showInactive: options?.showInactive,
        pagination: {
          page: 0,
          limit: options?.maxNodesPerRequest || DOWNLINE_MAX_LIMIT,
          orderBy: {
            'position.tree_level': 'ASC',
            's.id': 'ASC',
          },
        },
      };

    const result =
      await fetchDownline(dlParams, options?.maxNodesPerRequest);

    // console.log('VIA_DOWNLINE:', nodeId,
    //   'totalItems:', result.totalItems,
    //   'actualItems:', result.tableItems.length,
    //   'hitReqLimit:', result.hitRequestLimit,
    //   'batchSize:', result.batchSize,
    //   'batchCount:', result.batchCount,
    //   'maxNodes:', result.maxNodes,
    // );

    lastRequestHitLimit.value = result.hitRequestLimit ? 'downline' : false;
    lastFetchDLParams.value = {
      maxNodes: result.maxNodes,
      fetchedNodes: result.tableItems.length,
      dp: result.lastParams,
    };

    const noDuplicates = removeDuplicateNodes(result.tableItems);

    const myPositionId = nid.positionId ||
      noDuplicates.find((item) => {
        const treePos = tpFromItem(item);
        return treePos && sameNodeTP(treePos, nid);
      })?.['vmdl.id']?.raw;


    if (!myPositionId) {
      console.error('myPositionId not found nid=', nid, 'nodeId=', nodeId, 'list=', noDuplicates);
      throw new Error('myPositionId not found');
    }

    noDuplicates.forEach((item) => {

      const nodeId = nodeIdFromItem(item);
      if (!nodeId) throw new Error('should not happen 1: node id could not be created');

      let node = nodes.value[nodeId];
      if (!node) {
        node = createNodeFromItem(item);
        nodes.value[node.id] = node;
      }

      const qItem = Item(item);
      const foundSelf = qItem.positionId() === myPositionId;

      if (onlyKids) {
        const isMyDirectKid = (qItem.parentPositionId() === myPositionId);
        if (isMyDirectKid) {
          returnNodes.push(node);
          returnPositionIds.add(qItem.positionId());
        }
      } else {
        if (foundSelf) {
          returnNodes.push(node);
          returnPositionIds.add(qItem.positionId());
        }
      }

    });

    // populate children
    if (depth > 0) {

      const parents = new Set<TreeNode>();

      noDuplicates.forEach((item) => {

        const curNodeId = nodeIdFromItem(item) || '';
        const curNode = nodes.value[curNodeId];
        if (!curNode) throw new Error('should not happen 5: my node not found');

        const itemQuery = Item(item);
        const parentPositionId = itemQuery.parentPositionId();
        const parentAccountId = itemQuery.parentAccountId();

        if (itemQuery.positionId() === myPositionId) return; // self
        if (returnPositionIds.has(itemQuery.positionId())) return; // kids

        if (!parentPositionId || !parentAccountId) throw new Error('should not happen 2: node has no parent defined!');

        // don't have access to parent's offset parameter, so we find it by position
        const parentItem = noDuplicates.find((item) => {
          const i = Item(item);
          return i.accountId() === parentAccountId && i.positionId() === parentPositionId;
        });

        if (!parentItem) {
          console.log('orphant:', item, 'tableItems', result.tableItems, 'parentAccountId', parentAccountId, 'parentPositionId', parentPositionId);
          throw new Error('should not happen 3: parent item not found on deep fetch');
        }

        const parentNodeId = nodeIdFromItem(parentItem) || '';
        const parentNode = nodes.value[parentNodeId];

        if (!parentNode) throw new Error('should not happen 4: parent node not found');

        parentNode.open = true;
        parentNode.wasOpen = true;

        parentNode.children.push(curNode);
        parents.add(parentNode);

      });

      parents.forEach((parentNode) => {
        parentNode.children = normalizeChildren(parentNode.id, parentNode.children);
      });

    }

    setNodeLoading(nodeId, false);
    return returnNodes;
  }

  function mergeNodes(newNodes: TreeNode[]) {

    const parents = new Set<TreeNode>();

    newNodes.forEach((curNode) => {

      const itemQuery = Item(curNode.item);

      const parentPositionId = itemQuery.parentPositionId();
      if (!parentPositionId) return;

      const parentNode = Object.values(nodes.value).find((node) => {
        const i = Item(node.item);
        return i.positionId() === parentPositionId;
      });

      if (!parentNode)
        return;

      const parentAlreadyHasMe = parentNode.children.find((child) => sameNode(child.id, curNode.id));
      if (parentAlreadyHasMe) return;

      parentNode.open = true;
      parentNode.wasOpen = true;

      parentNode.children.push(curNode);
      parents.add(parentNode);

    });

    parents.forEach((parentNode) => {
      parentNode.children = normalizeChildren(parentNode.id, parentNode.children);
    });

  }


  async function fetchNodesViaDownline(params: { dp: DownlineServiceParams }) {

    // const nodeId = createNodeIdFromTP(params.dp);
    // setNodeLoading(nodeId, true);

    const returnNodes: TreeNode[] = [];

    const result = await fetchDownline(params.dp, options?.maxNodesPerRequest);

    lastFetchDLParams.value = {
      maxNodes: result.maxNodes,
      fetchedNodes: result.tableItems.length,
      dp: result.lastParams,
    };

    lastRequestHitLimit.value = result.hitRequestLimit ? 'downline' : false;

    const noDuplicates = removeDuplicateNodes(result.tableItems);

    noDuplicates.forEach((item) => {

      const node = createNodeFromItem(item);
      nodes.value[node.id] = node;
      returnNodes.push(node);

    });

    return returnNodes;
  }

  async function fetchNodesViaFrontline(params: { fp: FrontlineServiceParams }) {

      const returnNodes: TreeNode[] = [];

      const result = await fetchFrontline(params.fp, options?.maxNodesPerRequest);

      lastFetchFLParams.value = result.lastParams;
      lastRequestHitLimit.value = result.hitRequestLimit ? 'frontline' : false;

      const noDuplicates = removeDuplicateNodes(result.tableItems);

      noDuplicates.forEach((item) => {

        const node = createNodeFromItem(item);
        nodes.value[node.id] = node;
        returnNodes.push(node);

      });

      return returnNodes;
    }

  async function fetchViaFrontline(nodeId: string) {
    setNodeLoading(nodeId, true);
    const frontlineNodes: TreeNode[] = [];

    const nid = parseNodeId(nodeId);

    const result =
      await fetchFrontline({
        treeId,
        positionId: nid.positionId ? nid.positionId : undefined,
        offset: nid.positionId ? undefined : nid.offset ?? 1,
        accountId: nid.accountId,
        properties,
        filter: currentFilter,
        showInactive: options?.showInactive,
      }, options?.maxNodesPerRequest);

    // console.log('fetchViaFrontline', nodeId, 'totalItems:', result.totalItems);

    lastFetchFLParams.value = result.lastParams;
    lastRequestHitLimit.value = result.hitRequestLimit ? 'frontline' : false;

    removeDuplicateNodes(result.tableItems).forEach((item) => {
      const node = createNodeFromItem(item);
      frontlineNodes.push(node);
      nodes.value[node.id] = node;
      // console.log('  --new_node', node.id);
    });

    setNodeLoading(nodeId, false);

    return frontlineNodes;
  }

  function setNodeLoading(nodeId: string, value: boolean) {
    const node = nodes.value[nodeId];
    if (node) {
      node.loading = value;
      // true value is set on toggleKids, so we probably don't need to set it here
    }
  }

  function setTreeLoading(value: boolean) {
    loading.value = value;
  }

  function getNode(row: TableGridItem) {
    const id = nodeIdFromItem(row);
    if (!id) return undefined;
    return nodes.value[id];
  }

  function isOpen(row: TableGridItem) {
    const node = getNode(row);
    if (!node) return false;
    return node.open;
  }

  function toggleOpen(id: string) {
    const node = nodes.value[id];
    if (!node) {
      console.error('No node on toggle');
      console.log(nodes.value);
      return;
    }
    node.open = !node.open;
    if (node.open) {
      node.wasOpen = true;
      if (node.children.length === 0) {
        node.loading = true;
      }
    }
  }

  function getRoot() {
    return rootNode.value;
  }

  function getUplineRoot() {
    return uplineRootNode.value || rootNode.value;
  }

  function resetTree() {
    rootNode.value = undefined;
    tableItems.value = [];
    uplineRootNode.value = undefined;
    nodes.value = {};
  }

  async function fetchTree(accountId: number, depth = 1, offset = 1): Promise<TreeFetchResult> {
    setTreeLoading(true);

    try {
      if (!rootNode.value) {
        rootNode.value = await fetchRootPosition(accountId, depth, offset);
        if (uplineLimit > 0) {
          uplineRootNode.value = await fetchUplineRootNode(accountId, uplineLimit, offset);
        }

      }
      tableItems.value = await unwrap([rootNode.value], 0, depth);
      const totalItems = tableItems.value.length;

      return {
        tableItems: tableItems.value,
        totalItems,
        root: rootNode.value,
        uplineRoot: uplineRootNode.value || rootNode.value,
      };

    } finally {
      setTreeLoading(false);
    }

  }

  function cloneWithoutClosedKids(root: TreeNode) {
    const newNode = { ...root };
    if (!newNode.open) {
      newNode.children = [];
    } else {
      newNode.children = newNode.children.map(cloneWithoutClosedKids);
    }
    return newNode;
  }


  function updateRootNodes() {

    // recursively traverse from node and his kids and set his value = from nodes.value[id]
    let maxLevel = 0;

    const updateNode = (node: TreeNode, level = 0) => {
      const newNode = nodes.value[node.id];
      maxLevel = Math.max(maxLevel, level);

      if (!newNode) {
        throw new Error(`Node not found ${node.id}`);
      }
      // newNode.item = enrichItem(node.item, level, colorRules);

      newNode.children = newNode.children.map((n) => updateNode(n, level + 1));
      node.children = newNode.children;
      node.item = newNode.item;
      return node;
    };

    if (rootNode.value)
      rootNode.value = updateNode(rootNode.value, 0);

    if (uplineRootNode.value)
      uplineRootNode.value = updateNode(uplineRootNode.value, uplineStartLevel.value );

    return maxLevel;
  }

  async function loadMergeDownline(params: { dp: DownlineServiceParams }) {

    await fetchNodesViaDownline({ dp: params.dp });

    mergeNodes(Object.values(nodes.value));
    const maxLevel = updateRootNodes();

    if (rootNode.value)
      tableItems.value = await unwrap([rootNode.value], 0, maxLevel);

  }

  async function loadMergeFrontline(params: { fp: FrontlineServiceParams }) {

      await fetchNodesViaFrontline({ fp: params.fp  });

      mergeNodes(Object.values(nodes.value));
      const maxLevel = updateRootNodes();

      if (rootNode.value)
        tableItems.value = await unwrap([rootNode.value], 0, maxLevel);

  }

  async function continueFetch() {

    if (lastRequestHitLimit.value === false) return;

    if (lastRequestHitLimit.value === 'downline') {
        if (!lastFetchDLParams.value) return;
        setTreeLoading(true);
        try {
          const params = { ... lastFetchDLParams.value.dp };

          if (params.pagination) {
            params.pagination.page++;

            await loadMergeDownline({ dp: lastFetchDLParams.value.dp });
          }

        } finally {
          setTreeLoading(false);
        }
    }

    if (lastRequestHitLimit.value === 'frontline') {
        if (!lastFetchFLParams.value) return;
        setTreeLoading(true);
        try {
          const params = { ... lastFetchFLParams.value };
          if (params.pagination) {
            params.pagination.page++;
            await loadMergeFrontline({ fp: params });
          }
        } finally {
          setTreeLoading(false);
        }
    }

  }


  function getTableItems() {
    return tableItems.value;
  }

  return {
    loading,
    lastRequestHitLimit,

    fetchTree,
    continueFetch,

    getTableItems,
    getRoot,
    getUplineRoot,
    findItemByNodeId,

    isOpen,
    toggleOpen,
    resetTree,

    cloneWithoutClosedKids,
  } as const;
}
