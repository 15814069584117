<template>
  <div v-if="loading" class="d-md-flex justify-center pa-md-4">
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </div>
  <v-expand-transition>
    <div v-if="fullOrder">
      <v-table
        class="mt-8 mb-4 order-table"
      >
        <thead>
          <tr>
            <th class="text-center">{{ lt('image') }}</th>
            <th class="text-center">{{ lt('article') }}</th>
            <th class="text-center">{{ lt('title') }}</th>
            <th class="text-center">{{ lt('description') }}</th>
            <th class="text-center">{{ lt('price') }}</th>
            <th class="text-center">{{ lt('quantity') }}</th>
            <th class="text-center">{{ lt('total') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in fullOrder.catalogItemDocument" :key="item.id">
            <td class="text-center">
              <v-img
                v-if="item.catalogItem.images.length"
                :src="item.catalogItem.images[0].image.thumbUrl"
                :alt="item.catalogItem.title"
                width="96"
                height="96"
                class="ma-2 mx-auto"
                cover
              />
            </td>
            <td class="text-center">{{ item.catalogItem.article }}</td>
            <td class="text-center">{{ item.catalogItem.title }}</td>
            <td class="text-center">{{ item.catalogItem.description }}</td>
            <td class="text-center"><CellPrice :value="item.price" :currencyId="fullOrder.currencyId" /></td>
            <td class="text-center">{{ item.quantity }}</td>
            <td class="text-center">
              <CellPrice :value="item.price * item.quantity" :currencyId="fullOrder.currencyId" />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5" class="text-start"><b>{{ lt('total') }}</b></td>
            <td class="text-center"><b>{{ fullOrder.totalItemQuantity }}</b></td>
            <td class="text-center">
              <CellPrice :value="fullOrder.totalItemPrice" :currencyId="fullOrder.currencyId" />
            </td>
          </tr>
        </tfoot>
      </v-table>

      <!-- Volumes as chips at center -->
      <div class="d-flex justify-end mb-8">
        <v-chip
          v-for="volume in summaryVolumes"
          :key="volume.name"
          class="ma-2"
          color="primary"
          label
        >
          {{ volume.name }}: {{ volume.value }}
        </v-chip>
      </div>
    </div>
  </v-expand-transition>
</template>

<script setup lang="ts">
import { useLocalization } from '@/composables';
import { gql, GQLClient } from '@ui-api3-sdk/api/gql';
import { computed, onMounted, ref } from 'vue';
import CellPrice from '../../pos-entity-lib/CellPrice.vue';
import { PosOrderFromList, PosOrderFull } from '../PosOrdersHistory';

const props = defineProps<{
  order: PosOrderFromList;
}>();

const { lt } = useLocalization('uiPosOrderHistory');

const fullOrder = ref<PosOrderFull | null>(null);
const loading = ref(false);

const summaryVolumes = computed<Array<{ name: string; value: number }>>(() => {
  const volumes: Record<string, number> = {};
  fullOrder.value?.catalogItemDocument.forEach((item) => {
    Object.entries(item.volumes).forEach(([key, value]) => {
      if (volumes[key]) {
        volumes[key] += value;
      } else {
        volumes[key] = value;
      }
    });
  });

  return Object.entries(volumes).map(([name, value]) => ({ name, value }));
});

onMounted(async () => {
  loading.value = true;


  const data = await GQLClient.request(gql`${query}`, {
    documentId: props.order.id,
  }) as {
    posDocumentByPk: PosOrderFull
  };

  fullOrder.value = data.posDocumentByPk;

  loading.value = false;

});


const query=`
query DocumentByIdQuery($documentId: Int!) {
  posDocumentByPk(id: $documentId) {
    catalogItemDocument {
      catalogItemId
      discount
      price
      quantity
      volumes
      id
      catalogItem {
        images {
          fileStorageId
          image {
            imageUrl
            thumbUrl
          }
          id
        }
        id
        description
        title
        unit
        article
      }
    }
    createdAt
    creatorAdmin {
      accountId
      email
      firstname
      lastname
    }
    currencyId
    documentStatus {
      id
      color
      title
      status
    }
    isPaid
    isVolumeSent
    paidTotal
    paidTotalFromWallet
    periodId
    totalItemDiscount
    totalItemPrice
    totalItemQuantity
    notice
    number
    documentTypeId
  }
}
`;


/*
Response Example:

{
  "data": {
    "posDocumentByPk": {
      "catalogItemDocument": [
        {
          "catalogItemId": 1,
          "discount": 0,
          "price": 98,
          "quantity": 1,
          "volumes": {
            "PV": 16,
            "Purchases": 10
          },
          "id": 46,
          "catalogItem": {
            "images": [
              {
                "fileStorageId": 164,
                "image": {
                  "imageUrl": "https://mlmsoft-services-data.s3.rke2-eu-hetzner-dev.ds.mlmsoft.cloud/dev-ikravets/api3/images/_________________2024_08_20_10_55ffd5299b78032eedd55995431cfcc2374b73e3_full.png",
                  "thumbUrl": "https://mlmsoft-services-data.s3.rke2-eu-hetzner-dev.ds.mlmsoft.cloud/dev-ikravets/api3/thumbs/_________________2024_08_20_10_55ffd5299b78032eedd55995431cfcc2374b73e3.png"
                },
                "id": 217
              }
            ],
            "id": 1,
            "description": "asdasdasd",
            "title": "The_Product_2",
            "unit": "kg",
            "article": "0001"
          }
        },
        {
          "catalogItemId": 58,
          "discount": 0,
          "price": 15,
          "quantity": 3,
          "volumes": {
            "PV": 11,
            "Purchases": 12
          },
          "id": 45,
          "catalogItem": {
            "images": [],
            "id": 58,
            "description": "",
            "title": "Newest prod",
            "unit": "units",
            "article": "0007"
          }
        }
      ],
      "createdAt": "2025-01-30T08:05:21.768Z",
      "creatorAdmin": {
        "accountId": null,
        "email": "admin@mlm-soft.com",
        "firstname": "MLM Soft",
        "lastname": "Admin"
      },
      "currencyId": "USD",
      "documentStatus": {
        "id": 16,
        "color": "status-color-blue",
        "title": "Invoice created",
        "status": "in_stock"
      },
      "isPaid": null,
      "isVolumeSent": null,
      "paidTotal": 0,
      "paidTotalFromWallet": 0,
      "periodId": 6,
      "totalItemDiscount": 0,
      "totalItemPrice": 143,
      "totalItemQuantity": 4,
      "notice": "For owner, pos is main",
      "number": "2025-01-30/000005",
      "documentTypeId": 5
    }
  }
}

*/


/*
Inbound order example:
{
    "id": 12,
    "number": "2025-01-30/000005",
    "sourcePosId": 1,
    "createdAt": "2025-01-30T08:05:21.768Z",
    "creatorAdminId": 1,
    "isArchived": false,
    "documentTypeId": 5,
    "documentStatusId": 16,
    "notice": "For owner, pos is main",
    "properties": {
      "CPAccountId": ""
    },
    "isPaid": null,
    "currencyId": "USD",
    "sourcePos": {
      "title": "main"
    },
    "documentStatus": {
      "title": "Invoice created",
      "color": "status-color-blue"
    },
    "account": {
      "id": 2,
      "user": {
        "firstname": "Millis",
        "lastname": "Owner2"
      }
    },
    "creatorAdmin": {
      "firstname": "MLM Soft",
      "lastname": "Admin"
    },
    "totalItemQuantity": 4,
    "totalItemPrice": 143,
    "totalItemDiscount": 0,
    "paidTotal": 0,
    "paidTotalFromWallet": 0
  }
    */


</script>

<style scoped>
.order-table {
  border-spacing: 0;
  border-collapse: separate;

  padding-top: 8px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

}

.order-table th {
  padding: 12px 16px;
  font-weight: 500;
}

.order-table td {
  padding: 12px 16px;
  vertical-align: middle;
}

</style>
