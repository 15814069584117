import { get } from "lodash";
import { FieldTypeSimple, getI18nTitle } from "@ui-api3-sdk/api/gql";
import type { TableGridColumn, TableGridItem, TableGridItemProperty } from "./table-grid-interface";
import CellDate from "@/components/pos-entity-lib/CellDate.vue";
import CellYesNo from "@/components/pos-entity-lib/CellYesNo.vue";

export type CellViewComponentProps = {
  field?: string;
  value?: any;
  item?: TableGridItem;
}

// i18n
const t = (s: string) => s;

export function unwrapEntityToTableGridItem(entityShortName: string, object: any, fields: FieldTypeSimple[]) {

  const res: TableGridItem = {};

  fields.forEach((f) => {

    const baseName = f.fullName.replace(`${entityShortName}.`, '');

    if (baseName.includes('[]')) {

      const [arrayPath, remainder] = baseName.split('[].');

      const array = get(object, arrayPath);

      if (!array) return;

      if (!Array.isArray(array)) {
        console.error(`Expected array for ${arrayPath}`);
        return;
      }

      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        const value = get(item, remainder);

        const property: TableGridItemProperty = {
          presentable: String(value),
          raw: value,
        };

        const resName = `${entityShortName}.${arrayPath}[${i}].${remainder}`;

        res[resName] = property;
      }

      return;
    }

    const value = get(object, baseName);
    if (value === undefined) return;

    const property: TableGridItemProperty = {
      presentable: String(value),
      raw: value,
    };

    res[f.fullName] = property;

  });

  res.raw = object;
  return res;
}

export function createColumnDefinitionsFromSchemaFields(fieldTypes: FieldTypeSimple[], fields?: string[]): TableGridColumn[] {


  const resFieldNames = fields as string[] || fieldTypes.map((f) => f.fullName);

  const resColumns = resFieldNames.map((fieldName) => {

    const noIndexName = fieldName.replace(/\[\d+\]/, '[]');
    const field = fieldTypes.find((f) => f.fullName === noIndexName);
    if (!field) {
      console.log('fieldTypes[not_found_in_schema]', noIndexName, fieldTypes);
      throw new Error(`Field ${noIndexName} not found in schema`);
    }

    let component: any = undefined;

    if (field.type === 'Boolean') {
      component = CellYesNo;
    }

    if (field.type === 'DateTime') {
      component = CellDate;
    }

    // TODO: ending with Id - try to find corresponding entity to use EntitySelect

    // const fullName = `${modelName}.${field.name}`;

    const res: TableGridColumn = {
      name: fieldName,
      title: t(getI18nTitle(field)),
      sortable: true,
      viewComponent: component,
      filterComponent: undefined,
      fieldType: field.type,

      // noWrap: true,
      // filterable: true,
      // align: 'left',
      // width: 150,
    };
    return res;
  });

  // console.log('resFields', resFields);

  return resColumns;

}

export function createColumnDefinitionsFromDummy(modelName: string, dummy: Record<string, any>, fields?: string[]): TableGridColumn[] {


  const resFields = fields || Object.keys(dummy);

  return resFields.map((field) => {
    // TODO add components for specific fields

    const component: any = undefined;

    // if (typeof dummy[field] === 'boolean') {
    //   component = CellYesNo;
    // }

    const fullName = `${modelName}.${field}`;

    return {
      name: fullName,
      title: t(`model_${fullName}`),
      sortable: true,
      component,
      // filterable: true,
      // filterType: 'text',
      // type: typeof dummy[field],
      // align: 'left',
      // width: 150,
    };
  });
}
