export default {

  image: "Image",
  article: "Article",
  title: "Title",
  description: "Description",
  price: "Price",
  quantity: "Quantity",
  total: "Total",

}
