/* eslint-disable camelcase */
export default {
  buyTicket: 'Buy',
  readMore: 'Read more',
  share: 'Share',
  open: 'Open',
  back: 'Back',
  download: 'Download',
  seeAll: 'See all',
  shareNotSupported: 'The link has been copied to the clipboard!',
};
