
export interface CountdownTimerProps {

    id?: string;

    target?: string; // end date and time in UTC format, example: '2022-12-31T23:59:59'

    hideSeconds?: boolean;
    hideDays?: boolean;

    customPeridiocity?: 'weekly' | 'monthly' | 'daily';
    customPeriodStart?: string; // start date and time in UTC format, example: '2022-12-31T23:59:59'

}

export const defaults = {
    target: undefined,

    hideSeconds: false,
    hideDays: false,

    customPeridiocity: undefined,
    customPeriodStart: undefined,
};
