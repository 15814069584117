/* eslint-disable camelcase */
export default {
  account_information: "Account information",
  contact_details: "Contact details",
  phone_confirmation: "Phone confirmation",
  email_confirmation: "Email confirmation",

  register: "Register",
  you_have_registered: "You have successfully registered!",

  agreement_title: "Terms and conditions",
  agreement_label: "I agree to the terms and conditions",
  agreement_required: "You must agree to the terms and conditions",

};
