/**
 * Creates a compiled function to evaluate a value against a JavaScript condition.
 * @param jsCondition String containing JavaScript code returning boolean, checking 'value'.
 * @returns A function that accepts a value and returns a boolean result of the evaluation.
 */
export function createEvalValidator(jsCondition: string): (value: any) => boolean {
  const code = `return (value) => { return ${jsCondition}; };`;

  try {
    const fn = new Function(code)();
    return (value: any) => {
      try {
        return !!fn(value);
      } catch (e) {
        console.error('EC: Error evaluating value:', value, 'with condition:', jsCondition, e);
        return false;
      }
    };
  } catch (e) {
    console.error('EC: Failed to create validator for condition:', jsCondition, e);
    // Return a no-op validator that always returns false
    return () => false;
  }
}


function toCamelCase(str: string): string {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

/**
 * Creates a compiled function to evaluate a value against a JavaScript condition.
 * @param object Object containing values to be used in the condition.
 * @returns A function that accepts a condition string and returns a boolean result of the evaluation.
 */
export function createObjectValidator(object: Record<string, any>) {

  const keys = Object.keys(object);

  // eslint-disable-next-line prefer-template
  const code = keys.map((key) => `const ${toCamelCase(key)} = ${JSON.stringify(object[key])};`).join("\n\r") + '\n\r return ';

  return function checkCondition(jsCondition: string) {

    const codeCondition = `${code}${jsCondition};`;

    try {

      const fn = new Function(codeCondition);
      const res = fn();

      // console.log('EC: Evaluated condition', jsCondition, ' = ', res);

      return res;
    } catch (e) {
      console.error('EC: Failed to evaluate condition', jsCondition, e);
      return false;
    }
  };


}

// Example1:

// const isNumberGreaterThanTen = createEvalValidator('(value > 10) && (typeof value === "number")');

// console.log(isNumberGreaterThanTen(15)); // true
// console.log(isNumberGreaterThanTen('15')); // false
// console.log(isNumberGreaterThanTen(5)); // false

// Example2:

// const obj = {
//   firstName: 'John',
//   lastName: 'Connor',
//   age: 25,
// };

// const checkCondition = createObjectValidator(obj);
// console.log(checkCondition('age > 24 && firstName.includes(`i`)')); // true
