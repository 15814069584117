<template>
  <BasicCard :title="tableTitle" :headerClass="headerClass">
    <v-data-table-server
      v-model:options="options"
      :headers="headers"
      :items="items"
      :itemsLength="total"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"


      :itemsPerPage="noPagination ? -1 : options.itemsPerPage"
      :itemsPerPageOptions="noPagination ? undefined : [
        { value: 5, title: '5' },
        { value: 10, title: '10' },
        { value: 15, title: '15' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ]"
    >
      <template #item.operation="{ item: { operationType, operationIcon, operationColor, comment } }">
        <div class="d-flex">
          <v-icon :color="operationColor">
            {{ operationIcon }}
          </v-icon>
          <div class="d-inline-block ml-2">
            <div class="text-caption">
              {{ $t(operationType) }}
            </div>
            <div class="text-caption text--secondary">
              {{ comment }}
            </div>
          </div>
        </div>
      </template>

      <template #item.amount="{ item: { amount, currency, positive } }">
        <div class="text-wrap">
          <span
            class="text-caption text-no-wrap"
            :class="{ 'font-weight-bold success--text text--darken-1': positive }"
          >
            {{ amount }}
          </span>
          &nbsp;
          <span class="text-caption">{{ currency }}</span>
        </div>
      </template>

      <template #item.date="{ item: { date, time } }">
        <span
          v-if="date"
          class="font-weight-bold text-caption text-no-wrap"
        >
          {{ date }}
        </span>
        &nbsp;
        <span
          v-if="time"
          class="text--secondary text-caption text-no-wrap"
        >{{ time }}</span>
      </template>
    </v-data-table-server>
  </BasicCard>
</template>

<script>

import { defaults } from './WalletHistory.ts';


import { useAccountPropertyCache, useAwaitAccountId, useAwaitValue, useLocalization, useWallet } from '@/composables';
import { useEventBus } from '@/pinia/useEventBus';
import { wearMaskMoney } from '@/utils/money';
import { useWalletApi } from '@ui-api3-sdk/api/api3';
import { mapState } from 'pinia';

import BasicCard from './base/BasicCard.vue';


const { lt, la, d } = useLocalization('uiWalletHistory');

function getOperationObject (opt) {

  const isTransfer = /transfer/.test(opt);

  if (/^in:/.test(opt)) {
    return {
      operationType: operationCode(opt.slice(4)),
      operationIcon: isTransfer ? 'mdi-swap-horizontal-bold' : 'mdi-import',
      operationColor: isTransfer ? 'primary' : 'success',
    };
  }

  if (/^out:/.test(opt)) {
    return {
      operationType: operationCode(opt.slice(5)),
      operationIcon: isTransfer ? 'mdi-swap-horizontal-bold' : 'mdi-export-variant',
      operationColor: isTransfer ? 'primary' : 'warning',
    };
  }

  return {
    operationType: operationCode(opt),
    operationIcon: 'mdi-mdi-swap-horizontal-bold',
    operationColor: 'primary',
  };
}

function validatorDate (value) {
  // format 2021-10-01
  return /^[1-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]$/.test(value);
}

function operationCode (value) {
  switch (value) {
    case 'Adjustment':
      return la('op_adjustment');
    case 'Commission payment':
      return la('op_commission_payment');
    case 'Payout':
      return la('op_payout');
    case 'Account to account transfer':
      return la('op_account_to_account_transfer');
    case 'Administrative transfer':
      return la('op_administrative_transfer');
    case 'Purchase order':
      return la('op_purchase_order');
    default:
      return value;
  }
}

export default {
  name: 'WalletHistory',

  components: {
    BasicCard,
  },

  props: {

    id: {
      type: String,
      default: undefined,
    },

    operationTypes: {
      type: Array,
      default() {
        return [];
      },

      validator(val) {
        return val.every(item => typeof item === 'number');
      },
    },

    // [[country_id, wallet_id], ...]
    walletCountries: {
      type: Array,
      default() {
        return [];
      },

      validator(val) {
        return val.every(item => Array.isArray(item) && item.length === 2 && typeof item[0] === 'string' && typeof item[1] === 'number');
      },
    },

    start: {
      type: String,
      validator: validatorDate,
      default: null,
    },

    end: {
      type: String,
      validator: validatorDate,
      default: null,
    },

    wallet: {
      type: Number,
      default: null,
    },

    title: {
      type: String,
      default: '',
    },

    headerClass: {
      type: String,
      default: defaults.headerClass,
    },

    noPagination: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // isGrid: true,
    loading: false,
    currencyId: '',
    walletName: '',
    items: [],
    aId: null,
    options: {},
    total: 0,
    walletFetched: false,
    walletId: null,
  }),

  computed: {

    ...mapState(useEventBus, ['walletHistoryUpdated']),

    headers() {
      return [
        {
          value: 'operation',
          title: lt('operation'),
          sortable: false,
          key: 'operation',
        },
        {
          value: 'amount',
          title: lt('amount'),
          sortable: false,
          key: 'amount',
        },
        {
          value: 'date',
          title: lt('date'),
          sortable: false,
          key: 'date',
        },
      ];
    },

    tableTitle() {
      return this.title || lt('operation_history_for', { n1: this.walletName || lt('loading') });
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },

    walletHistoryUpdated() {
      this.fetch();
    },
  },

  methods: {

    async fetch() {
      const { page, itemsPerPage: limit } = this.options;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      this.aId = await useAwaitAccountId(this.accountId);

      this.loading = true;

      if (!this.walletFetched) {
        await this.getWalletParams();
      }

      if ([undefined, null].includes(this.walletId)) {
        return;
      }

      try {
        await useWalletApi().getOperations({
          id: this.aId,
          walletId: this.walletId,
          limit,
          page  : page - 1,
          operationTypeIds: this.operationTypes,
          start: this.start || undefined,
          end: this.end || undefined,
        }, {
          headers: {
            'Locale': 'en',
          },
        })
          .then((res) => {
            const { list:items, total } = res.data.payload;

            this.total = total;

            this.items = items.map(item => {
              const dateObj = new Date(item.date);

              const date = d(dateObj, 'medium');
              const time = d(dateObj, {
                hour: '2-digit',
                minute: '2-digit',
              });

              return {
                ...(getOperationObject.call(that, item.walletOperationType.title)),
                comment: item.comment || '',
                date,
                time,
                amount: `${wearMaskMoney(item.amount.split('.').join(''))}`,
                currency: item.currency,
                positive: parseFloat(item.amount) > 0,
              };
            });
          });
      } finally {
        this.loading = false;
      }
    },

    async getWalletParams() {
      this.walletId = this.wallet;

      if (this.walletCountries.length) {
        // todo: why walletCounties is passed by props?
        // shouldn't it be in the server response / store ???

        const countryProp = await useAwaitValue(
          useAccountPropertyCache('country_id'), 'prop',
        );

        const countryId = String(countryProp.raw).toLowerCase();

        if (countryId) {
          const walletCountry = this.walletCountries.find(
            wc => wc[0].toLowerCase() === countryId,
          );

          if (walletCountry) this.walletId = +walletCountry[1];
        }
      }

      if (!this.walletId)
        throw new Error('wallet is not specified or not matching current country');

      const wallet = await useAwaitValue(
        useWallet(this.aId, this.walletId), 'currentWallet',
      );

      this.currencyId = wallet.currency_id;
      this.walletName = wallet.title;

      this.walletFetched = true;
    },
  },
};
</script>

<style lang="scss" module>
.header {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}
</style>
