<template>
  <!-- class="main rounded" -->
  <div
    :style="outsideBorder"
  >
    <v-alert
      v-if="errorMessage"
      class="mb-4"
      type="error"
      closable
      @click:close="emit('updateError', '')"
    >
      {{ $t(errorMessage) }}
    </v-alert>
    <!-- <div>opt {{ options }}</div>
    <div>def {{ defaultPagination }}</div> -->
    <!-- v-model:options="options" -->
    <v-data-table-server
      v-model:expanded="expanded"
      v-resize="onResize"
      :showExpand="true"
      :options="options"
      :headers="headers"
      :items="modifiedItems"
      :itemsLength="totalItems"
      :loading="loading"
      :itemValue="item => item.srcItem.raw.id"

      :itemsPerPage="noPagination || !options ? -1 : options.itemsPerPage"
      :itemsPerPageOptions="noPagination ? undefined : [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ]"
      @update:options="onUpdateOptions($event)"
    >
      <template #expanded-row="eProps">
        <tr>
          <td :colspan="columns.length">
            <slot name="expanded" v-bind="eProps">
              <pre>{{ eProps.item.srcItem.raw }}</pre>
            </slot>
          </td>
        </tr>
      </template>

      <template #item="{ item, internalItem, isExpanded, toggleExpand }">
        <tr
          :style="item.rowColor?.background ? `background-color: ${item.rowColor.background}` : undefined"
        >
          <td
            v-for="(column) in columnsWithExpand"
            :key="column.name"
          >
            <div v-if="column.name === 'data-table-expand'">
              <v-icon
                @click="toggleExpand(internalItem)"
              >
                {{ isExpanded(internalItem) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </div>
            <div v-else-if="! column.name.includes('[]') || column.noArraySpread">
              <slot
                v-if="!column.viewComponent"
                :name="safeSlotName(column.name)"
                v-bind="slotParams(item.srcItem, item.srcItem[column.name]?.presentable)"
              >
                <span :class="{ 'text-no-wrap': column.noWrap }">
                  {{ presentableValue(column, item.srcItem) }}
                </span>
              </slot>
              <component
                :is="column.viewComponent"
                v-else
                v-bind="column.viewComponentProps"
                :item="item.srcItem"
                :value="item.srcItem[column.name]?.raw"
                :field="column.name"
              />
            </div>
            <div v-else>
              <div
                v-for="(array) in getListOfArrayItems(item.srcItem, column.name)"
                :key="array"
              >
                <slot
                  v-if="!column.viewComponent"
                  :name="safeSlotName(column.name)"
                  v-bind="slotParams(item.srcItem, item.srcItem[array]?.presentable)"
                >
                  {{ item.srcItem[array]?.presentable }} &nbsp;
                </slot>
                <component
                  :is="column.viewComponent"
                  v-else
                  :item="item.srcItem"
                  :value="item.srcItem[array]?.raw"
                  :field="array"
                />
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template
        v-if="!hideHeader"
        #top
      >
        <div
          v-if="$slots.top || allowSwitchMode"
          class="d-flex justify-content-center flex-column flex-md-row flex-lg-row flex-xl-row toolbox-padding"
          :class="mdAndUp ? 'mdAndUp' : undefined"
        >
          <slot name="top">
            <v-switch
              v-if="allowSwitchMode"
              v-model="isGrid"
              class="pl-3"
              :label="$t('tableGrid.show_as_grid')"
              hideDetails
            />
          </slot>
        </div>

        <v-expansion-panels
          v-if="!isGrid"
          class="mb-4"
        >
          <template
            v-for="(item, i) in modifiedItems"
            :key="`item_${i}`"
          >
            <slot
              name="expansion"
              :item="item"
              :flattner="tbgToFlatObject"
              :resultColumns="columns"
            >
              <v-expansion-panel>
                <v-expansion-panel-title
                  :style="item.rowColor?.background ? `background-color: ${item.rowColor.background}` : undefined"
                >
                  <!-- {{ item.srcItem[headers[0].key].presentable }} -->
                  <CellJsonChips
                    field="header"
                    :value="item.srcItem.raw"
                    :limit="4"
                  />
                </v-expansion-panel-title>
                <v-expansion-panel-text class="pt-4">
                  {{ item.srcItem.raw }}
                </v-expansion-panel-text>
              </v-expansion-panel>
            </slot>
          </template>
        </v-expansion-panels>
      </template>
      <template
        v-if="!isGrid && !nothingFound"
        #body
      />
      <template
        v-if="!isGrid"
        #headers
      />
      <template
        v-if="!showPagination"
        #bottom
      />
    </v-data-table-server>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRaw } from 'vue';

import { type DataTableServerOptions, mapPaginationToVuetify, slotParams, type TableGridColumn, type TableGridItem, type TableGridPaginationState } from '@/utils/entity-grid/table-grid-interface';

import { ColorScheme } from '@/utils/conditional-colors';
import { safeSlotName } from '@/utils/entity-grid/entity-grid-utils';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

import CellJsonChips from '../pos-entity-lib/CellJsonChips.vue';

type TableGridProps = {
  columns: TableGridColumn[];
  items: TableGridItem[],
  totalItems: number;

  loading?: boolean;
  autoSwitchMode?: boolean;
  allowSwitchMode?: boolean;
  drawBorder?: boolean;
  maximumLimit?: number;
  errorMessage?: string;
  noPagination?: boolean;

  defaultPagination?: TableGridPaginationState;
  hideHeader?: boolean;
  useExpandbleRows?: boolean;
};

const props = defineProps<TableGridProps>();
const { t } = useI18n();

const emit = defineEmits<{
  (event: 'paginationUpdate', result: TableGridPaginationState): void,
  (event: 'updateError', result: string): void,
}>();

const { mdAndUp } = useDisplay();

const isGrid = ref(true);

const options = ref<DataTableServerOptions | undefined>(mapPaginationToVuetify(props.defaultPagination));

const expanded = ref<string[]>([]);

const nothingFound = computed(() => props.items.length === 0 && !props.loading);

const columnsWithExpand = computed<TableGridColumn[]>(() => {
  if (! props.useExpandbleRows) return props.columns;
  const spreadControlColumn: TableGridColumn = {
    name: 'data-table-expand',
    title: '',
    sortable: false,
  };
  return [ ... props.columns, spreadControlColumn ];
});

const modifiedItems = computed(() => props.items.map((dataItem) => {
  type InternalItem =
    {
      rowColor?: ColorScheme,
      srcItem: TableGridItem,
    };

  const item: InternalItem  = {
    srcItem: dataItem,
  } as InternalItem;

  item['rowColor'] = dataItem['rowColor'];

  return item;
}));


const headers = computed(() =>  {

  const cols = props.columns.map((column) => ({
    title: t(column.title),
    key: column.name,
    value: column.name,
    sortable: column.sortable,
  }));

  if (props.useExpandbleRows)
    cols.push({ title: '', key: 'data-table-expand' } as any);

  return cols;

});

const showPagination = computed(() => {
  if (props.noPagination === true) return false;
  if (props.noPagination === false) return true;
  if (props.totalItems)
    return props.items.length < props.totalItems;
  return false;
});

const outsideBorder = computed(() => {
  const doDraw = props.drawBorder === undefined ? isGrid.value : props.drawBorder;
  return doDraw ? 'border: 2px solid #e0e0e0;' : undefined;
});


function presentableValue(column: TableGridColumn, item: TableGridItem) {
  if (column.format) {
    return column.format(item[column.name]?.raw, item);
  }

  const raw = item[column.name]?.raw;
  if (raw === undefined || raw === null) return '';

  const pr = item[column.name]?.presentable;
  if (! pr ) return pr;

  return String(pr);
}


function tbgToFlatObject(t: TableGridItem) {
  const obj: Record<string, string> = {};
  for (const column of props.columns) {
    const key = column.title || column.name || '';
    obj[key] = String(presentableValue(column, t));
  }

  return obj;

}

function getListOfArrayItems(item: TableGridItem, arrayFieldName: string) {

  const [firstPart, lastPart] = arrayFieldName.split('[]');

  if (firstPart === undefined || lastPart === undefined) throw new Error('Invalid arrayFieldName');
  if (firstPart.includes('[]') || lastPart.includes('[]')) throw new Error('Invalid arrayFieldName');

  const names: string[] = [];
  let i = 0;
  while (item[`${firstPart}[${i}]${lastPart}`]) {
    names.push(`${firstPart}[${i}]${lastPart}`);
    i++;
  }

  return names;

}

function onResize() {
  if (props.autoSwitchMode) isGrid.value = !(window.innerWidth < 800);
}

function onUpdateOptions(newOptions: DataTableServerOptions) {

  const msg: TableGridPaginationState = toRaw({
    page: newOptions.page,
    limit: newOptions.itemsPerPage > 0 ? newOptions.itemsPerPage : (props.maximumLimit ?? Number.MAX_SAFE_INTEGER),
    orderBy: toRaw(newOptions.sortBy),
  });

  emit('paginationUpdate', msg);
}

</script>

<style lang="scss" scoped>
.main {
  padding-top: 1em;
  padding-bottom: 1em;
}

.toolbox-padding {
  padding-right: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
}

.mdAndUp {
  gap: 1em;
  padding-top: 0;
}

</style>
