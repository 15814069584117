<template>
  <div
    class="d-flex flex-wrap align-center"
    style="gap: 10px"
  >
    <slot name="before" />
    <div
      v-for="({ key, value, color }) in items"
      :key="key"
    >
      <v-chip
        v-if="!props.onlyValues"
        :color="color"
        size="small"
      >
        {{ key }} = {{ value }}
      </v-chip>
      <v-chip
        v-else
        :color="color"
        size="small"
      >
        {{ value }}
      </v-chip>
    </div>
    <slot name="after" />
  </div>
</template>

<script lang="ts" setup>
import { getHashedColor } from '@/utils/entity-grid/entity-grid-utils';
import type { CellViewComponentProps } from "@/utils/entity-grid/table-grid-to-entity";
import { computed } from 'vue';

const props = withDefaults(defineProps<CellViewComponentProps & {
  limit?: number;
  onlyValues?: boolean;
}>(), {
  limit: 10,
  onlyValues: false,
});

const items = computed(() =>
  Object.entries(props.value)
    .filter(([,value]) => ['string', 'number', 'boolean'].includes(typeof value) && value)
    .map(([key, value]) => ({
      key: String(key),
      value: String(value),
      color: getHashedColor(String(key)),
    }))
    .sort((a, b) => a.key.localeCompare(b.key))
    .splice(0, props.limit),
);

</script>
~/services/table-grid-to-entity
