import { gravComponentSchema } from '../deep-form-grav';
import { CountdownTimerProps, defaults } from '../../../../components/ui/CountdownTimer';

const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<CountdownTimerProps>({
  title: 'Countdown Timer',
  link: 'https://docs.ds.mlmsoft.cloud/doc/countdowntimer-tajmer-obratnogo-otschyota-7xWjgpzN4M/',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('target').input('vtext')
  .title('End date and time in UTC (or ISO 8601) format')
  .desc('Example: 2024-01-01T10:00:00.000+07:00. If not spcified, will use the end of the current period')
  .props({ clearable: true })
  .col(6);

s.add('hideSeconds').input('boolean')
  .title('Hide seconds').col(3);

s.add('hideDays').input('boolean')
  .title('Hide days').col(3);

  //

s.add('customPeridiocity').input('dictionary').data(['weekly', 'monthly', 'daily'])
  .title('Custom periodicity')
  .desc('If set along with customPeriodStart, will calculate the time till the next period')
  .props({ clearable: true })
  .col(4);

s.add('customPeriodStart').input('vtext')
  .title('Custom period start date')
  .desc('Use this along with customPeridiocity to ignore backend period and use you custom period. Example: 2024-01-01T10:00:00.000+07:00')
  .props({ clearable: true })
  .col(8);

function text() {
  return `
<b>Countdown timer</b>
Used to display a countdown timer till the end of the current period (by default) or specific date, or custom
periodicity.
`;
}
