<template>
  <div class="pa-4">
    <div v-if="!xs && !!item.image">
      <div class="v-row">
        <div class="v-col-md-6">
          <MediaPreview :item="item" />
        </div>
        <div class="v-col-md-6">
          <PostHeader :item="item" />
          <PostSummary class="mt-2" :item="item" />
          <div class="mt-8 d-flex justify-center">
            <BtnOpen class="ml-2" :itemId="item.id" @open-item="emit('openItem', item.id)" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <PostHeader :item="item" />
      <MediaPreview :item="item" />
      <PostSummary class="mt-2" :item="item" />

      <div class="mt-4 d-flex justify-center">
        <BtnOpen class="ml-2" :itemId="item.id" @open-item="emit('openItem', item.id)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDisplay } from 'vuetify';
import { ParsedFeedItem } from '../../JsonFeed';

import BtnOpen from '../parts/BtnOpen.vue';
import MediaPreview from '../parts/MediaPreview.vue';
import PostHeader from '../parts/PostHeader.vue';
import PostSummary from '../parts/PostSummary.vue';

defineProps<{ item: ParsedFeedItem }>();

const emit = defineEmits<{
  (event: 'openItem', id: string): void;
}>();

const { xs } = useDisplay();
</script>
