export function safeSlotName(name: string) {
  const newName =  name.replace(/[^a-zA-Z\d_]/g, '_').toLocaleLowerCase();
  // console.log('safeSlotName', name, '->', newName);
  return newName;
}

export function isUndefined(v: any) {
  if (Array.isArray(v)) {
    return v.length === 0;
  }
  return v === undefined || v === null || v === '';
}

export function getHashedColor(str: string): string {

  const listOfColors = [
    'blue-grey',
    'purple',
    'orange',
    'pink',
    'brown',
    'indigo',
    'teal',
    'cyan',
    'gray',
    'lime',
    'amber',
    'deep-orange',
    'deep-purple',
    'blue-grey',
  ];

  function hashStringToIndex(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash) % listOfColors.length;
  }

  const index = hashStringToIndex(str);
  return listOfColors[index];
}
