import { ColorScheme } from "@/utils/conditional-colors";
import { fullName, idWithOffset, namePlusId, presentableId } from "@/utils/properties-calculated";

import emptyAvatar from '@/assets/images/avatar_undefined.png';
import { runTemplate } from '../utils/property-template-service';

export type TableGridItemProperty = {
  raw: string | number | object | boolean | undefined | null,
  presentable: string | number | object | undefined | null,
}

export type TableGridItem = Record<string, TableGridItemProperty> & {
  rowColor?: ColorScheme;
}

export interface TableGridPaginationState {
  page: number;
  limit: number;
  orderBy?: {
    [key: string]: 'ASC' | 'DESC';
  };
}

export type ItemType = ReturnType<typeof Item>;

export type PresentableValueFn = (prop: string) => string;
export type RawValueFn = (prop: string) => TableGridItemProperty['raw'];

export function itemsEqual(item1?: TableGridItem, item2?: TableGridItem) {
  if (!item1 || !item2)
    return false;
  return (item1['s.id']?.raw === item2['s.id']?.raw)
    && (item1['vmdl.offset']?.raw === item2['vmdl.offset']?.raw);
}

function presentableValueFactory(item?: TableGridItem) {
  return (prop: string) => item ? String(item[ prop ]?.presentable || '')  : '';
}

function rawValueFactory( item?: TableGridItem) {
  return (prop: string) => item ? item[ prop ]?.raw : undefined;
}

function templateFactory(item?: TableGridItem, encode = false) {
  return (template: string) => item ? runTemplate(template, item, undefined, encode) : '';
}


export function slotParams(item?: TableGridItem, value?: any, additional: Record<string, TableGridItemProperty['raw']> = {}) {

  return {
    item,
    value,
    p: presentableValueFactory(item),
    r: rawValueFactory(item),
    t: templateFactory(item),
    te: templateFactory(item, true),
    ...additional,
  };
}

// convenience item wraper
export function Item(row: TableGridItem) {

  function accountId() {
    return Number(row['s.id']?.raw);
  }

  function positionId() {
    return Number(row['vmdl.id']?.raw || 0);
  }

  function kids() {
    return Number(row['vmdl.childrenCount']?.raw);
  }

  function level() {
    // when we fetch whole downline, we rely on vmdl.level from api
    // when we gradually fetch branches using useGealogy, we rely on calculated t.level
    const lvl =
      row['t.level']?.raw !== undefined ? Number(row['t.level']?.raw) : Number(row['vmdl.level']?.raw);

    return lvl;
  }

  function offset() {
    return Number(row['vmdl.offset']?.raw || 1);
  }

  function treeId() {
    return Number(row['vmdl.treeId']?.raw);
  }

  function parentPositionId() {
    return Number(row['vmdl.parentPositionId']?.raw);
  }

  function parentAccountId() {
    return Number(row['parent.id']?.raw);
  }

  function number() {
    return Number(row['vmdl.number']?.raw);
  }

  function avatarUrl() {
    if (typeof row['p.avatar_id']?.raw !== 'object' || !row['p.avatar_id']?.raw)
      return emptyAvatar;

    const avatarProp: { thumbUrl?: string } = row['p.avatar_id'].raw;
    return avatarProp.thumbUrl ?? emptyAvatar;
  }

  function email() {
    return String(row['p.email']?.raw || '');
  }

  function sameAs(item: ItemType) {
    if ( accountId() === item.accountId() && offset() === item.offset() )
      return true;
    return false;
  }

  const p = (prop: string) => row ? String(row[ prop ]?.presentable || '')  : '';
  const r = (prop: string) => row ? row[ prop ]?.raw : undefined;
  const t = (template: string) => row ? runTemplate(template, row) : '';
  const n = (prop: string) => Number(row[ prop ]?.raw || 0);

  return {
    accountId,

    presentableId: () => presentableId(row),
    idWithOffset: () => idWithOffset(row),
    fullName: () => fullName(row),
    namePlusId: () => namePlusId(row),

    avatarUrl,
    email,
    positionId,
    kids,
    level,
    offset,
    number,
    parentPositionId,
    parentAccountId,
    treeId,

    sameAs,
    getRaw: () => row,
    p, t, r, n,

  };
}
