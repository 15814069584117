<template>
  <TableGrid
    v-bind="tableProps"
    :autoSwitchMode="autoSwitchMode"
    :noPagination="true"
    :columnActions="resultColumnActions"
  >
    <template #bottom="">
      <div
        v-if="lastRequestHitLimit"
        class="ml-4"
      >
        <v-tooltip location="top">
          {{ lt('limit_hit') }}
          <template #activator="{ props }">
            <VBtn

              color="error"
              :disabled="loading"
              variant="tonal"
              v-bind="props"
              @click="loadMore"
            >
              {{ lt('load_more') }}
            </VBtn>
          </template>
        </v-tooltip>
      </div>
    </template>


    <template #top>
      <!-- <div class="flex-grow-1" /> -->
      <AccountSearch
        v-model="selectedAccount"
        style="max-width: 600px;"
        :networkSearch="false"
        class="flex-1-1"
        :inputProperties="inputProperties"
        :showDisabledAccounts="showDisabledAccounts"
        @update:model-value="changeRootAccount()"
      />
    </template>
    <template #expansion="{ item }">
      <v-card class="w-100">
        <v-card-text>
          <div class="d-flex align-center" :style="offsetStyle(item.srcItem,1.5)">
            <MemberInfoHeader
              :data="item.srcItem"
              :columnActions="resultColumnActions"
            />
            <v-spacer />
            <v-btn
              v-if="Item(item.srcItem).kids() > 0"
              size="large"
              variant="text"
              :icon="isOpen(item.srcItem) ? 'mdi-chevron-down' : 'mdi-chevron-right'"
              @click.stop="toggleRow(item.srcItem)"
            />
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template
      v-for="(prop, index) in usedProperties.properties"
      #[`${prop}`]="{ item, value }"
    >
      <span v-if="item && ((prop === buttonOnField)||(String(index) === buttonOnField))" :key="`id-${prop}`" class="text-no-wrap" :style="offsetStyle(item)">
        <slot :name="prop" :item="item" :value="value">
          {{ value }}
        </slot>
        <v-btn
          v-if="Item(item).kids() > 0"
          size="small"
          variant="text"
          :icon=" isOpen(item) ? 'mdi-chevron-down' : 'mdi-chevron-right'"
          @click="toggleRow(item)"
        />
      </span>
      <slot v-else :name="prop" :item="item" :value="value">
        {{ value }}
      </slot>
    </template>
  </TableGrid>
</template>

<script setup lang="ts">

import { GenealogyTableProps, defaults } from "./GenealogyTable.ts";

import { setupTableColumnActions } from '@/composables/useTableColumnActions';
import { Item, TableGridItem, TableGridPaginationState } from "@/models/table-grid-interface";

import { FRONTLINE_MAX_LIMIT } from '@/utils/fetch-frontline-service';

import { useAccountId, useLocalization, useTree } from '@/composables';
import { useTableGrid } from '@/composables/useTableGrid';

import { useProperties } from "@/composables/useProperties";
import { nodeIdFromItem } from "@/models/tree-interface";

import { AccountSearchItem } from "@/utils/account-search-view";
import { computed, ref } from "vue";

import AccountSearch from "./base/AccountSearch.vue";
import MemberInfoHeader from "./base/MemberInfoHeader.vue";
import TableGrid from './base/TableGrid.vue';

const { lt } = useLocalization('treeRender');

const props = withDefaults(defineProps<GenealogyTableProps>(),{
  id: undefined,
  headerTitles: undefined,
  accountId: undefined,
  popupInfoOnField: undefined,
  popupProperties: undefined,
  inputProperties: undefined,
  showInactive: undefined,
  maxNodesPerRequest: () => defaults.maxNodesPerRequest,
  treeId: () => defaults.treeId,
  autoSwitchMode: () => defaults.autoSwitchMode,
  buttonOnField: () => defaults.buttonOnField,
});

const usedProperties = useProperties(
  props.properties,
  [
    's.id',
    'r.id',
    'p.avatar_id',
    'p.firstname',
    'p.lastname',
    'vmdl.childrenCount',
  ],
  props.headerTitles,
);

const { tableProps, tableMethods } = useTableGrid({
  properties: usedProperties.properties,
  requestProperties: usedProperties.allFetchableProperties(),
  headerTitles: props.headerTitles,
  fetch,
  maximumLimit: FRONTLINE_MAX_LIMIT,
},
);

const resultColumnActions = setupTableColumnActions(props);


const { fetchTree, toggleOpen, isOpen, resetTree, continueFetch, getTableItems, lastRequestHitLimit, loading } = useTree(
  props.treeId,
  usedProperties.propsWithMandatory.value,
  [],
  undefined,
  undefined,
  {
    showInactive: props.showInactive,
    maxNodesPerRequest: props.maxNodesPerRequest,
  },
);
const { aId: accountId } = useAccountId(props.accountId);

const selectedAccount = ref<AccountSearchItem | undefined>();
const resultAccountId = computed(() => selectedAccount.value?.accountId || accountId?.value);

function offsetStyle(item: TableGridItem, multiplicator = 1) {
  const level = Math.max(0, (item['t.level'].raw as number - 1)*multiplicator);
  return { paddingLeft: `${level}em` };
}
const showDisabledAccounts = computed(() => props.showInactive === 'only_disabled' || props.showInactive === 'all_inactive')

function toggleRow(row: TableGridItem) {
  const id = nodeIdFromItem(row);
  if (!id) throw new Error('No id');
  toggleOpen(id);
  tableMethods.doFetch();
}

function changeRootAccount() {
  resetTree();
  tableMethods.doFetch();
}


async function fetch(properties: string[], pagination: TableGridPaginationState) {
  if (! resultAccountId.value) {
    console.error('No accountId');
    return {
      tableItems: [],
      totalItems: 0,
    };
  }
  tableMethods.setLoading(true);

  const result = await fetchTree(resultAccountId.value, 1, 0);

  tableMethods.setLoading(false);
  return result;
}

async function loadMore() {
  // console.log('loadMore', lastFecthParams);
  try {
    tableMethods.setLoading(true);
    await continueFetch();
    const items = getTableItems();
    console.log('items', items);
    tableMethods.setItems(getTableItems());
    tableMethods.setLoading(false);
  } catch (e) {
    loading.value = false;
    console.error(e);
    // errorMsg.value = apiErrorToString(e, 'Error fetching tree');
  } finally {
    tableMethods.setLoading(false);
  }
}

</script>
