
import { ColorRules } from '@/utils/conditional-colors';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { FilterItem } from '@ui-api3-sdk/api/api3';
import { DownlineServiceParams } from '@/utils/fetch-downline-service';

export type VisualBinaryTreeProps = {
  id?: string;

  properties?: string[];
  popupProperties?: string[],

  treeId?: number;
  headerTitles?: Record<string, string>;
  colors?: ColorRules;
  inputProperties?: InputByTypeProperties;
  backgroundColor?: string;
  initialFetch?: number,
  shortFetch?: number,
  longFetch?: number,

  uplineLimit?: number,

  frontlineFilter?: Array<FilterItem>,
  frontlineTreeId?: number,
  frontlineProperties?: string[],

  titleField?: string,
  subtitleField?: string,
  titleFieldMax?: number,
  subtitleFieldMax?: number,

  editableProperties?: string[],
  editPermission?: 'self' | 'all' | 'none',

  disablePlacement?: boolean,
  disabledPlacementText?: string,
  disableNavigateToLowest?: boolean,

  placementDocument?: string,
  placementPayloadType?: 'v1' | 'v2' | 'v3',
  showInactive?: DownlineServiceParams['showInactive'];
  maxNodesPerRequest?: number;
  forcePlacementMode?: boolean;
}

export const defaults = {
  treeId: 0,
  backgroundColor: 'rgb(196, 201, 250, 0.5)',
  initialFetch: 2,
  shortFetch: 1,
  longFetch: 3,
  uplineLimit: 500,
  frontlineTreeId: 0,
  frontlineProperties: ['r.id', 'p.firstname'],
  frontlineFilter: [{ field: 'm.BinaryPositions', operation: '=', value: '0' }],

  titleField: 't.fullName',
  subtitleField: 't.accountPlusOffset',
  titleFieldMax: 25,
  subtitleFieldMax: 30,

  editableProperties: ['m.SpilloverDirection'],
  editPermission: 'self' as const,

  disablePlacement: false,
  disableNavigateToLowest: false,

  placementDocument: 'BinaryPositionCreate',
  placementPayloadType: 'v1' as const,
  maxNodesPerRequest: 250,
  forcePlacementMode: false,
};
