<template>
  <v-btn
    v-if="itemId"
    color="primary"
    @click="emit('openItem', itemId)"
  >
    {{ title || $t('uiJsonFeed.open') }}
  </v-btn>
</template>

<script setup lang="ts">
defineProps<{ itemId?: string, title?: string }>();
const emit = defineEmits<{
  (event: 'openItem', id: string): void;
}>();

</script>
