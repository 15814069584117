import { LinkBoxProps } from "./LinkBox";

export type ParsedFeedItem = {
  id: string;

  date: string;
  title: string;

  summary: string;
  image: string;
  html: string;

  tags: string[];

  url?: string;
  externalUrl?: string;
};


export interface JsonFeedProps {
  id?: string;
  url: string;
  mode?: 'fancy' | 'events' |  'blog' | 'academy' | 'resources' | 'videos';
  linkboxProps?: Partial<LinkBoxProps>;
}

export const defaults = {
  mode: 'events' as const,
};
