import { gravComponentSchema } from '../deep-form-grav';
import { LinkBoxProps, FileEntry, defaults } from '../../../../components/ui/LinkBox';


const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<LinkBoxProps>({
  title: 'LinkBox',
  link: 'https://www.notion.so/mlmsoft/LinkBox-94ed557d4ff24692b8aedc737f794663',
  text: text(),
},
  defaults,
);

export default toExport;

const mdWidthInput = l.spawn<string>('mdiWidth')
  .input('dictionary').data(
    () => Array.from({ length: 12 }, (_, i) => i + 1).map(i => ({ title: `${i}`, value: `v-col-md-${i}` })),
  );

const fileEntryProps = {
  dfShowTitle: true,
  class: 'mr-md-4',
  clearable: true,
  //hideDetails: true,
  density: 'compact' as const,
  // variant: 'outlined' as const,
};

const fileEntryInput = l.spawn<FileEntry>('fileEntry').input('dform').data([
  l.spawn<string>('link').input('vtext').title('Link')
    .props(fileEntryProps)
    .col(8).mandatory().item(),
  l.spawn<string>('title').input('vtext').title('Title')
    .props(fileEntryProps)
    .col(4).item(),

  l.spawn<string>('icon').input('icon').title('Icon')
    .props(fileEntryProps)
    .col(3).item(),
  l.spawn<string>('class').as(mdWidthInput).title('Width')
    .props(fileEntryProps)
    .col(2).item(),
  l.spawn<string>('backgroundClass').input('mdiColor').title('Background color')
    .props(fileEntryProps)
    .col(3).item(),
  l.spawn<string>('badge').input('vtext').title('Badge text')
    .props(fileEntryProps)
    .col(4).item(),
])
  .init(() => ({ link: '', title: '', icon: '', backgroundClass: '', badge: '', class: '' }))
  .props({ style: 'border: 1px solid #ccc; padding: 20px; margin: 10px; border-radius: 5px;' });


s.add('title').input('vtext')
  .title('Title').col(6);

s.add('backgroundClass').input('mdiColor')
  .title('Form background color').col(3);

s.add('headerClass').input('mdiColor')
  .title('Header color').col(3);


s.add('fileBackgroundClass').input('mdiColor')
  .title('Default file background color').col(3);

s.add('fileIcon').input('icon')
  .title('Default file icon').col(3);

s.add('fileClass').as(mdWidthInput)
  .title('Default File button width').col(3);

s.add('rowHeight').as(bl.clone('number'))
  .title('Forced row height in px').col(3);

s.add('files').input('dlist').data(fileEntryInput.item()).title('Files').col(12);

function text() {
  return `
<b>Documents and Links</b>
MLM Soft platform is a flexible website platform that allows you to create any different pages in the main menu. You may want to have one or a couple pages with a downloadable file list or a list of links.
You can use it to share any kind of business materials, presentations, certificates, and/or legal documents with your sales reps.
If you want to have more than one page like this with different kinds of materials, please just copy paste this section to provide your customization preferences for each wallet.
`;
}
