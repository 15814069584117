
<template>
  <LinkBox
    v-for="category in categories"
    :key="category.name"
    :title="category.name"
    backgroundClass="bg-blue-grey"
    fileBackgroundClass="bg-primary elevation-2"
    :files="category.linkBoxFiles"
    v-bind="linkboxProps || {}"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ParsedFeedItem } from '../../JsonFeed';
import { type LinkBoxProps } from '../../LinkBox.ts';
import LinkBox from '../../LinkBox.vue';

// ******* Resources / Материалы
// Выводит список материалов (презентаций) из соответствующего фида.

// Материалы разделены по категориям (это tags из фида). По каждой категории выводится:
// Название категории
// Первые несколько постов в категории, по каждому посту:
// Картинки
// Название
// Линк See all / Смотреть все , который показывает все посты выбранной категории

// Клик на See all показывает только посты выбранной категории в виде сокращенной ленты,
// в которой для каждого поста выводится только картинка и название, соответственно помещается
// 2 поста в одну строку:

// При клике на пост (картинку либо название) открывается отдельный экранчик:
// Названием
// embedded PDF с данным материалом (например через https://pub.dev/packages/flutter_pdfview)
// Кнопка Download/Скачать (сохранить себе на телефон)
// Кнопка Share/Поделиться


const props = withDefaults(defineProps<{
  items: ParsedFeedItem[],
  linkboxProps?: Partial<LinkBoxProps>;
}>(), {
  linkboxProps: undefined,
});

type Category = {
  name: string;
  items: ParsedFeedItem[];
  linkBoxFiles: { link: string; title: string }[];
  // previewItems: ParsedFeedItem[];
};

// const openItemId = ref<string | null>(null);
// const dialog = ref(false);
// const openedItem = computed(() => props.items.find(item => item.id === openItemId.value));

const categories = computed<Category[]>(() => {
  const categories = props.items.reduce((acc, item) => {
    item.tags.forEach(tag => {
      if (!acc[tag]) {
        acc[tag] = [];
      }
      acc[tag].push(item);
    });
    return acc;
  }, {} as Record<string, ParsedFeedItem[]>);

  return Object.entries(categories).map(([name, items]) => ({
    name,
    items,
    linkBoxFiles: items.map(item => ({
      link: item.externalUrl || item.url || '',
      title: item.title,
    })),
    // previewItems: items.slice(0, props.previewItemsPerTag),
  }));
});

// watch(openItemId, (newVal) => {
//   dialog.value = newVal !== null;
// });

// watch(dialog, (newVal) => {
//   if (!newVal) {
//     openItemId.value = null;
//   }
// });

</script>
