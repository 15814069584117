<template>
  <v-btn
    color="primary"
    @click="emit('back')"
  >
    {{ title || $t('uiJsonFeed.back') }}
  </v-btn>
</template>

<script setup lang="ts">
defineProps<{ title?: string }>();
const emit = defineEmits<{
  (event: 'back'): void;
}>();

</script>
