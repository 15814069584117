<template>
  <div>
    <ItemPreview
      v-for="item in items"
      :key="item.id"
      class="mb-4"
      :item="item"
      @open-item="openItemId = $event"
    />
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.display.xs"
    >
      <v-card>
        <v-card-text>
          <ItemFull
            v-if="openedItem"
            :item="openedItem"
            @back="dialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { ParsedFeedItem } from '../../JsonFeed';
import ItemFull from './ItemFull.vue';
import ItemPreview from './ItemPreview.vue';

// ******* mode = blog, Events / События
// Выводит посты блога из фида events.
// Показаны ТОЛЬКО посты у которых дата ≥ сегодня.
// Отсортированы по дате по возрастанию.

// По каждому посту в списке:
// Дата
// Название
// Картинка
// Краткий текст

// При клике открывается экранчик где:
// Дата
// Название
// Картинка
// Полный текст
// Кнопка Share/Поделиться которая видна только если есть URL
// Кнопка Buy ticket/Купить билет которая видна только если есть external_url и открывает этот УРЛ в браузере


const props = defineProps<{
  items: ParsedFeedItem[],
}>();

const openItemId = ref<string | null>(null);
const dialog = ref(false);
const openedItem = computed(() => props.items.find(item => item.id === openItemId.value));

watch(openItemId, (newVal) => {
  dialog.value = newVal !== null;
});

watch(dialog, (newVal) => {
  if (!newVal) {
    openItemId.value = null;
  }
});
</script>
