export default {

  image: "Изображение",
  article: "Артикул",
  title: "Название",
  description: "Описание",
  price: "Цена",
  quantity: "Количество",
  total: "Итого",

}
